import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { messageApi } from "../../services/AuthServices";
import { useNavigate } from "react-router-dom";

function MainSection() {
  const [isDisable, setisDisable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

const AdminSchema = object().shape({
  email: string()
    .required("Please enter email.")
    .email("Invalid Email Format. Please try again.")
    .trim("Please enter email.")
    .max(50, "Email must be at most 50 characters."),

  firstName: string()
    .required("Please enter first name.")
    .trim("Please enter first name.")
    .max(30, "First name must be at most 30 characters."),

  lastName: string()
    .required("Please enter last name.")
    .trim("Please enter last name.")
    .max(30, "Last name must be at most 30 characters."),

  message: string()
    .required("Please enter Message.")
    .trim("Please enter Message.")
    .max(500, "Message must be at most 500 characters."),
});

  const submitClicked = async (data) => {
    setisDisable(true);

    await messageApi(data)
      .then((response) => {
        setisDisable(false);
        resetForm();

        toast("Message Send Successfully", { type: "success" });
        navigate("/");
      })
      .catch((error) => {
        setisDisable(false);
        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    resetForm,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      message: "",
      mobile: "",
    },
    onSubmit: async () => {
      let formdata = new FormData();
      formdata.append("first_name", values.firstName);
      formdata.append("last_name", values.lastName);
      formdata.append("email", values.email);
      formdata.append("mobile", values.mobile);
      formdata.append("message", values.message);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  return (
    <>
      <div className="binduz-er-map-area">
        <div
        // className=" container"
        >
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-map-box">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380379.500621907!2d-111.9307474!3d34.16803495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b08ebcb4c186b%3A0x423927b17fc1cd71!2sArizona%2C%20USA!5e0!3m2!1sen!2sin!4v1721936276727!5m2!1sen!2sin"
                  width="100"
                  height="600"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  aria-hidden="false"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="binduz-er-contact-us-area ">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div
                className="binduz-er-contact-us-box pt-4 box-shadow"
                style={{ marginTop: "30px", padding: "0px 60px 60px" }}
              >
                <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
                  <div className="binduz-er-contact-title">
                    <h4 className="binduz-er-title">
                      GET IN TOUCH & LET US KNOW
                    </h4>
                    <span style={{ fontSize: "smaller", fontWeight: "500" }}>
                      You can also contact us at
                      <a href="mailto:Info@azindia.com"> Info@azindia.com</a>
                    </span>
                  </div>
                  <div className="row">
                    <div className=" col-lg-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Enter First Name*"
                          name="firstName"
                          id="firstName"
                          maxLength={30}
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.firstName && errors?.firstName && "red",
                          }}
                        />
                        <i className="fal fa-user"></i>
                      </div>
                      {touched?.firstName && errors?.firstName && (
                        <span className="text-danger">{errors.firstName}</span>
                      )}
                    </div>
                    <div className=" col-lg-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Enter Last Name*"
                          name="lastName"
                          id="lastName"
                          maxLength={30}
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.lastName && errors?.lastName && "red",
                          }}
                        />
                        <i className="fal fa-user"></i>
                      </div>
                      {touched?.lastName && errors?.lastName && (
                        <span className="text-danger">{errors.lastName}</span>
                      )}
                    </div>

                    <div className=" col-lg-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="email"
                          placeholder="Enter Email*"
                          name="email"
                          id="email"
                          maxLength={50}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.email && errors?.email && "red",
                          }}
                        />
                        <i className="fal fa-envelope"></i>
                      </div>
                      {touched?.email && errors?.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                    <div className=" col-lg-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="number"
                          placeholder="Enter Mobile Number"
                          name="mobile"
                          id="mobile"
                          value={values.mobile}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <i className="fal fa-phone"></i>
                      </div>
                    </div>
                    {/* <div className=" col-lg-4">
                      <div className="binduz-er-input-box binduz-er-select-item">
                        <DropDown
                          datas={category1}
                          selected={selectedCategory}
                          action={changeCategory1}
                          className="mexuvo-binduz-tech-dropdown"
                        />
                      </div>
                    </div> */}
                    <div className=" col-lg-12">
                      <div className="binduz-er-input-box">
                        <textarea
                          cols="30"
                          rows="10"
                          placeholder="Enter Message*"
                          name="message"
                          id="message"
                          maxLength={500}
                          value={values.message}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            marginBottom:
                              !touched?.message && !errors?.message && "22px",
                            borderColor:
                              touched?.message && errors?.message && "red",
                          }}
                        ></textarea>
                        <i className="fal fa-pencil"></i>
                      </div>
                      {touched?.message && errors?.message && (
                        <span className="text-danger">{errors.message}</span>
                      )}{" "}
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={isDisable}
                    onClick={handleSubmit}
                    className="binduz-er-main-btn contact-us-box-button "
                    style={{ marginBottom: "7px" }}
                  >
                    {isDisable ? "Loading..." : "Submit Request"}
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="row pt-90">
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Corporate Office</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Main HQ</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn active" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Advertise</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default MainSection;
