/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { array, object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextEditor from "./TextEditor";
import { getEventCatData, getLocation } from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { updateEvent } from "../../services/AuthServices";
import {
  dateSendToBackend,
  formatDate,
  formatDateToMMDDYYYY,
  getDatefromBackend,
  minimizeName,
} from "../../lib/constant";
import _ from "lodash";
import Select from "react-select";
import LocationModal from "../PostEvent/LocationModal";


const ClassiFieldForm = () => {
  const [eventCatData, seteventCatData] = useState([]);
  const [locationDetails, setlocationDetails] = useState([]);
  const [isLocation, setisLocation] = useState(true);
  // const [imagePreview, setImagePreview] = useState(null);
  const [isDisable, setisDisable] = useState(false);
  const [locationSearch, setlocationSearch] = useState("");
  const [locationModal, setlocationModal] = useState(false);
  const navigate = useNavigate();
  let fullName = localStorage.getItem("full_name");
  const location = useLocation();
  let eventDetail = location?.state;
  const [selectedLogo, setselectedLogo] = useState(
    eventDetail?.flyer
      ? process.env.REACT_APP_IMAGE_BASE_URL + eventDetail?.flyer
      : null
  );
  const [selectedImage, setselectedImage] = useState(
    eventDetail?.image
      ? process.env.REACT_APP_IMAGE_BASE_URL + eventDetail?.image
      : null
  );
  const [selectedEmailLogo, setselectedEmailLogo] = useState(
    eventDetail?.sponsor_email_logo
      ? process.env.REACT_APP_IMAGE_BASE_URL + eventDetail?.sponsor_email_logo
      : null
  );
  const [selectedSponsorLogo, setselectedSponsorLogo] = useState(
    eventDetail?.sponsor_logo
      ? process.env.REACT_APP_IMAGE_BASE_URL + eventDetail?.sponsor_logo
      : null
  );

  const logoInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const emailLogoInputRef = useRef(null);
  const sponsorLogoInputRef = useRef(null);

  useEffect(() => {
    getCatData();
    getLocationData();
  }, []);

  const getCatData = async () => {
    await getEventCatData()
      .then((response) => {
        seteventCatData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getLocationData = async (id) => {
    await getLocation()
      .then((response) => {
        setlocationDetails(response?.data?.data);
      })
      .catch((error) => {});
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser()?.parseFromString(html, "text/html");
    return doc?.body?.textContent || "";
  };

  const AdminSchema = object().shape({
    eventName: string()
      .required("Please enter name.")
      .trim("Please enter name.")
      .min(3, "Name must be at least 3 characters.")
      .max(50, "Name cannot exceed 50 characters."),
    location_list: array()
      .of(
        object().shape({
          location_id: string().required("Location is required"),
          time: string().required("Time is required"),
          date: string().required("Date is required"),
        })
      )
      .min(1, "At least one location is required"),

    video: array().of(
      object().shape({
        url: string().matches(
          /^(https?:\/\/)/,
          "URL must start with http:// or https://"
        ),
      })
    ),

    ticket_url: string().matches(
      /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/,
      "Please enter a valid URL starting with http:// or https://"
    ),
    eventId: string()
      .required("Please select event type.")
      .trim("Please select event type."),

    details: string()
      .required("Please enter Details.")
      .trim("Please enter Details.")
      .test("is-valid", "Details cannot be empty", (value) => {
        const strippedValue = stripHtmlTags(value).trim();
        return strippedValue.length > 0;
      }),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    await updateEvent(data)
      .then((response) => {
        setisDisable(false);

        toast("Event Updated Successfully", { type: "success" });
        navigate("/calender");
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const covertTime = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    const timeString = date.toLocaleTimeString("en-US", options);
    return timeString;
  };

  const locationListArray = (array) => {
    return array?.map((a) => ({
      location_id: a?.location_id,
      date: getDatefromBackend(a?.date),
      time: a?.time ? a?.time?.substring(0, 5) : null,
    }));
  };

  const videoArray = (array) => {
    return array?.map((a) => ({
      url: a?.link || "",
    }));
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      eventName: eventDetail?.name || "",
      eventId: eventDetail?.type_id || "",
      ticket_url: eventDetail?.ticket_url || "",
      logo: eventDetail?.flyer || "",
      video: videoArray(eventDetail?.video) || [{ url: "" }],
      location_list: locationListArray(eventDetail?.location) || [
        {
          location_id: "",
          date: "",
          time: "",
          name: "",
          city_id: "",
          state_id: "",
          address: "",
          latitude: "",
          longitude: "",
          zipcode: "",
        },
      ],
      image: eventDetail?.image || "",
      sponsor_email_logo: eventDetail?.sponsor_email_logo || "",
      sponsor_logo: eventDetail?.sponsor_logo || "",
      details: eventDetail?.description || "",
    },
    onSubmit: async () => {
      let userId = localStorage.getItem("userId");

      let formdata = new FormData();

      values?.location_list.forEach((item, index) => {
        if (_.isEmpty(item?.name)) {
          formdata.append(
            `location_list[${index}][location_id]`,
            item?.location_id
          );
        } else {
          formdata.append(`location_list[${index}][location_id]`, null);
        }

        formdata.append(
          `location_list[${index}][date]`,
          dateSendToBackend(item?.date)
        );
        formdata.append(`location_list[${index}][time]`, item.time);
        formdata.append(`location_list[${index}][name]`, item?.name);
        formdata.append(`location_list[${index}][city_id]`, item?.city_id);
        formdata.append(`location_list[${index}][state_id]`, item?.state_id);
        formdata.append(`location_list[${index}][address]`, item?.address);
        formdata.append(`location_list[${index}][latitude]`, item?.latitude);
        formdata.append(`location_list[${index}][longitude]`, item?.longitude);
        formdata.append(`location_list[${index}][zipcode]`, item?.zipcode);
      });
      values?.video?.forEach((item, index) => {
        formdata.append(`video[${index}][url]`, item?.url);
      });

      formdata.append("name", values.eventName);
      formdata.append("type_id", values.eventId);
      formdata.append("id", eventDetail?.id);
      formdata.append("ticket_url", values?.ticket_url);
      formdata.append("sponsor_email_logo", values.sponsor_email_logo);
      formdata.append("sponsor_logo", values.sponsor_logo);
      formdata.append("image", values.image);
      formdata.append("user_id", userId);
      formdata.append("description", values.details);
      formdata.append("flyer", values?.logo);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const dateChange = (value, index) => {
    let array = values?.location_list?.map((a, i) =>
      i === index ? { ...a, date: value } : a
    );
    setFieldValue("location_list", array);
  };

  const timeChange = (value, index) => {
    let array = values?.location_list?.map((a, i) =>
      i === index ? { ...a, time: value } : a
    );
    setFieldValue("location_list", array);
  };

  const locationChange = (value, index) => {
    let array = values?.location_list?.map((a, i) =>
      i === index ? { ...a, location_id: value } : a
    );
    setFieldValue("location_list", array);
  };

  const options = locationDetails.map((x) => ({
    value: x.id,
    label: x.name,
  }));

  const addLocation = (index) => {
    if (values?.location_list?.length < 5) {
      setFieldValue("location_list", [
        ...values?.location_list,
        {
          location_id: "",
          date: "",
          time: "",
        },
      ]);
    } else {
      toast("You�ve exceeded the limit for the locations you can upload", {
        type: "error",
      });
    }
  };

  const deleteLocation = (index) => {
    let array = [];
    for (let [i, a] of values?.location_list?.entries()) {
      if (index !== i) {
        array?.push(a);
      }
    }
    setFieldValue("location_list", array);
  };

  const handleImageChange = (event, name) => {
    const file = event?.target?.files[0];
    setFieldValue(name, file);
    if (name === "logo") {
      setselectedLogo(URL.createObjectURL(file));
    } else if (name === "image") {
      setselectedImage(URL.createObjectURL(file));
    } else if (name === "sponsor_email_logo") {
      setselectedEmailLogo(URL.createObjectURL(file));
    } else if (name === "sponsor_logo") {
      setselectedSponsorLogo(URL.createObjectURL(file));
    }
  };

  const addURL = () => {
    if (values?.video?.length < 5) {
      setFieldValue("video", [
        ...values?.video,
        {
          url: "",
        },
      ]);
    }
  };

  const deleteURL = (index) => {
    let array = [];
    for (let [i, a] of values?.video?.entries()) {
      if (index !== i) {
        array?.push(a);
      }
    }
    setFieldValue("video", array);
  };

  const onChangeURL = (value, index) => {
    let array = values?.video?.map((a, i) =>
      i === index ? { url: value } : a
    );

    setFieldValue("video", array);
  };

  const removeImage = (name) => {
    setFieldValue(name, null);
    if (name === "logo") {
      setselectedLogo(null);
      logoInputRef.current.value = "";
    } else if (name === "image") {
      setselectedImage(null);
      imageInputRef.current.value = "";
    } else if (name === "sponsor_email_logo") {
      setselectedEmailLogo(null);
      emailLogoInputRef.current.value = "";
    } else if (name === "sponsor_logo") {
      setselectedSponsorLogo(null);
      sponsorLogoInputRef.current.value = "";
    }
  };

  const getNewLocation = (newLocation) => {
    let randomId =
      Math.floor(Math.random() * (2000 - 1000 + 1)) +
      1000 +
      values?.location_list?.length;
    setlocationDetails([
      ...locationDetails,
      { ...newLocation, id: randomId, name: newLocation?.locationName },
    ]);
    setFieldValue("location_list", [
      {
        location_id: randomId,
        date: "",
        time: "",
        name: newLocation?.locationName,
        city_id: newLocation?.city,
        state_id: newLocation?.state,
        address: newLocation?.address,
        latitude: newLocation?.latitude,
        longitude: newLocation?.longitude,
        zipcode: newLocation?.zipCode,
      },
      ...values?.location_list,
    ]);
  };


  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <p>
            Submit your event for inclusion in our calendar using this form.
            Once reviewed, it will be added. Please attach your flyer in Word,
            JPG, JPEG, or PNG format. If your location is not listed, or if you
            have any questions about event submissions, please{" "}
            <Link
              to="/contact"
              style={{ textDecoration: "underline", color: "red" }}
            >
              reach out
            </Link>{" "}
            to us for assistance.
          </p>
          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Event Name *"
                name="eventName"
                id="eventName"
                value={values.eventName}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.eventName && errors?.eventName && "red",
                }}
              />
            </div>
            {touched?.eventName && errors?.eventName && (
              <span className="text-danger">{errors.eventName}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Event Type"
                name="eventId"
                onBlur={handleBlur}
                onChange={(e) => {
                  // getLocationData(e?.target?.value);
                  setFieldValue("eventId", e?.target?.value);
                }}
                value={values?.eventId}
                style={{
                  borderColor: touched?.eventId && errors?.eventId && "red",
                }}
              >
                <option value="">Event Type</option>
                {eventCatData?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
              {touched?.eventId && errors?.eventId && (
                <span className="text-danger">{errors.eventId}</span>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="User Name *"
                name="username"
                disabled
                id="username"
                value={fullName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <label htmlFor="logo" className="custom-file-label">
                {minimizeName(values?.logo?.name, 25) || "Choose Flyer"}
              </label>
              <input
                className="form-control custom-file-input"
                type="file"
                placeholder="Logo *"
                accept="image/*"
                ref={logoInputRef}
                name="logo"
                id="logo"
                onChange={(e) => handleImageChange(e, "logo")}
                onBlur={handleBlur}
              />
            </div>
            {selectedLogo && (
              <>
                <a
                  // href={values?.image}
                  href={selectedLogo}
                  target="_blank"
                  className="m-1"
                >
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("logo")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
            <span>( Max Width/Height: 1150px * 650px )</span>
          </div>
          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Ticket Url"
                name="ticket_url"
                id="ticket_url"
                value={values.ticket_url}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.ticket_url && errors?.ticket_url && "red",
                }}
              />
            </div>
            {touched?.ticket_url && errors?.ticket_url && (
              <span className="text-danger">{errors.ticket_url}</span>
            )}
          </div>

          {!values?.isFree && (
            <div className="col-lg-6">
              <div className="binduz-er-input-box">
                <label htmlFor="image" className="custom-file-label">
                  {minimizeName(values?.image?.name, 25) || "Choose Image"}
                </label>
                <input
                  type="file"
                  placeholder="Image *"
                  name="image"
                  id="image"
                  accept="image/*"
                  ref={imageInputRef}
                  className="form-control custom-file-input"
                  onChange={(e) => handleImageChange(e, "image")}
                  onBlur={handleBlur}
                />
              </div>
              {selectedImage && (
                <>
                  <a href={selectedImage} target="_blank" className="m-1">
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a className="m-1" onClick={() => removeImage("image")}>
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 650px )</span>
            </div>
          )}

          {!values?.isFree && (
            <div className="col-lg-6">
              <div className="binduz-er-input-box">
                <label
                  htmlFor="sponsor_email_logo"
                  className="custom-file-label"
                >
                  {minimizeName(values?.sponsor_email_logo?.name, 25) ||
                    "Sponsor Email Logo"}
                </label>
                <input
                  type="file"
                  placeholder="sponsor_email_logo *"
                  name="sponsor_email_logo"
                  id="sponsor_email_logo"
                  accept="image/*"
                  ref={emailLogoInputRef}
                  style={{ paddingLeft: "66px" }}
                  className="form-control custom-file-input"
                  onChange={(e) => handleImageChange(e, "sponsor_email_logo")}
                  onBlur={handleBlur}
                />
              </div>
              {selectedEmailLogo && (
                <>
                  <a href={selectedEmailLogo} target="_blank" className="m-1">
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a
                    className="m-1"
                    onClick={() => removeImage("sponsor_email_logo")}
                  >
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 650px )</span>
            </div>
          )}
          {!values?.isFree && (
            <div className="col-lg-6">
              <div className="binduz-er-input-box">
                <label htmlFor="sponsor_logo" className="custom-file-label">
                  {minimizeName(values?.sponsor_logo?.name, 25) ||
                    "Sponsor Logo"}
                </label>
                <input
                  type="file"
                  placeholder="sponsor_logo *"
                  name="sponsor_logo"
                  id="sponsor_logo"
                  accept="image/*"
                  ref={sponsorLogoInputRef}
                  className="form-control custom-file-input"
                  onChange={(e) => handleImageChange(e, "sponsor_logo")}
                  onBlur={handleBlur}
                />
              </div>
              {selectedSponsorLogo && (
                <>
                  <a
                    // href={values?.image}
                    href={selectedSponsorLogo}
                    target="_blank"
                    className="m-1"
                  >
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a
                    className="m-1"
                    onClick={() => removeImage("sponsor_logo")}
                  >
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 650px )</span>
            </div>
          )}
          {values?.video?.map((x, i) => {
            let isErrorURL = false;

            if (!_.isEmpty(touched?.video) && !_.isEmpty(errors?.video)) {
              const touchedItem = touched?.video[i] || false;
              const errorItem = errors?.video[i] || false;

              isErrorURL = !!(touchedItem?.url && errorItem?.url) || false;
            }
            return (
              <div className="d-flex">
                <div className="col-lg-10" style={{ paddingLeft: "0px" }}>
                  <div className="binduz-er-input-box">
                    <input
                      type="url"
                      placeholder="Video URL "
                      name="URL"
                      id="URL"
                      value={x?.url}
                      style={{
                        borderColor: isErrorURL && "red",
                      }}
                      onChange={(e) => onChangeURL(e?.target?.value, i)}
                    />
                  </div>

                  {isErrorURL ? (
                    <span className="text-danger">{errors?.video[i]?.url}</span>
                  ) : (
                    <>
                      {i === 0 && (
                        <span>{`URL (must have http:// or https:// )`}</span>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="col-lg-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <a
                    className="classifield-text"
                    onClick={() => {
                      addURL();
                    }}
                    style={{
                      height: "40px",
                      // marginLeft: "25px",
                      display: "flex",
                      // marginRight: "43px",
                      alignItems: "center",
                    }}
                  >
                    <i className="fal fa-plus"></i>
                  </a>
                  {i !== 0 && (
                    <a
                      className="classifield-text"
                      onClick={() => {
                        deleteURL(i);
                      }}
                      style={{
                        height: "40px",
                        // marginLeft: "25px",
                        display: "flex",
                        // marginRight: "43px",
                        alignItems: "center",
                      }}
                    >
                      <i className="fal fa-trash"></i>
                    </a>
                  )}
                </div>
              </div>
            );
          })}

          <div
            className="col-lg-13"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              className="classifield-text col-lg-3 "
              // onClick={() => {
              //   if (!values?.eventId) {
              //     toast("Please select event type", {
              //       type: "error",
              //     });
              //   } else {
              //     setisLocation(!isLocation);
              //   }
              // }}
              style={{
                height: "40px",
                // marginLeft: "25px",
                display: "flex",
                // marginRight: "43px",
                alignItems: "center",
              }}
            >
              {"Event Location Timing"}
            </a>
            <a
              className="classifield-text col-lg-2 "
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                if (values?.location_list?.length > 4) {
                  toast(
                    "You�ve exceeded the limit for the locations you can upload",
                    { type: "error" }
                  );
                } else {
                  setlocationModal(true);
                }
              }}
            >
              {"Create Location"}
            </a>
          </div>
          {isLocation && (
            <>
              {values?.location_list?.map((x, index) => {
                let isErrorTime = false;
                let isErrorDate = false;
                let isErrorId = false;

                if (
                  !_.isEmpty(touched?.location_list) &&
                  !_.isEmpty(errors?.location_list)
                ) {
                  const touchedItem = touched?.location_list[index] || false;
                  const errorItem = errors?.location_list[index] || false;

                  isErrorId =
                    !!(touchedItem?.location_id && errorItem?.location_id) ||
                    false;
                  isErrorTime =
                    !!(touchedItem?.time && errorItem?.time) || false;
                  isErrorDate =
                    !!(touchedItem?.date && errorItem?.date) || false;
                }

                return (
                  <>
                    <div
                      className="col-lg-10 d-flex "
                      style={{ justifyContent: "space-around" }}
                    >
                      <div className="col-lg-3">
                        {/* <div className="binduz-er-input-box ">
                          <select
                            placeholder="Location"
                            name="location"
                            id="location"
                            value={values?.location_list[index]?.location_id}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              locationChange(e?.target?.value, index);
                            }}
                            style={{
                              borderColor: isErrorId && "red",
                            }}
                          >
                            <option value="">Select Location</option>
                            {locationDetails?.map((x) => (
                              <option value={x?.id}>{x?.name}</option>
                            ))}
                          </select>
                          {isErrorId && (
                            <span className="text-danger">
                              {errors?.location_list[index]?.location_id}
                            </span>
                          )}
                        </div> */}
                        <div className="binduz-er-input-box">
                          <div className="binduz-er-dropdown-container">
                            <Select
                              id="location"
                              name="location"
                              value={
                                options.find(
                                  (option) =>
                                    option.value ===
                                    values?.location_list?.[index]?.location_id
                                ) || null
                              }
                              onChange={(e) => locationChange(e?.value, index)}
                              onBlur={handleBlur}
                              options={options}
                              placeholder="Select Location"
                              isSearchable
                              classNamePrefix="binduz-er"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  width: "100%",
                                  height: "60px",
                                  // paddingLeft: "30px",
                                  fontSize: "14px",
                                  border: isErrorId
                                    ? "2px solid red"
                                    : "2px solid #d8d8d8",
                                  boxShadow: state.isFocused
                                    ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                                    : "none",
                                  "&:hover": { borderColor: "#FF6821" },
                                }),
                                menu: (base) => ({
                                  ...base,
                                  boxShadow: "0 32px 32px rgba(0, 0, 0, 0.1)",
                                  zIndex: 10,
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#FF6821"
                                    : "white",
                                  color: state.isFocused ? "white" : "black",
                                  padding: "10px",
                                }),
                              }}
                            />
                            {isErrorId && (
                              <span className="text-danger">
                                {errors?.location_list[index]?.location_id}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="binduz-er-input-box ">
                          <ReactDatePicker
                            placeholderText="Date"
                            autoComplete="off"
                            minDate={new Date()}
                            // selected={values?.date}
                            value={values?.location_list[index]?.date}
                            onChange={(e) => {
                              // setFieldValue(
                              //   "date",
                              //   formatDateToMMDDYYYY(new Date(e))
                              // );
                              dateChange(
                                formatDateToMMDDYYYY(new Date(e)),
                                index
                              );
                            }}
                            onBlur={handleBlur}
                            name="date"
                          />
                        </div>
                        {isErrorDate && (
                          <span className="text-danger">
                            {errors?.location_list[index]?.date}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-3 ">
                        <div className="binduz-er-input-box ">
                          <ReactDatePicker
                            placeholderText={"time"}
                            showTimeSelect
                            autoComplete="off"
                            showTimeSelectOnly
                            onBlur={handleBlur}
                            value={values?.location_list[index]?.time}
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            timeCaption="Time"
                            // selected={values?.time}
                            onChange={(e) => {
                              timeChange(covertTime(new Date(e)), index);
                            }}
                            name="time"
                          />
                        </div>
                        {isErrorTime && (
                          <span className="text-danger">
                            {errors?.location_list[index]?.time}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="col-lg-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <a
                        className="classifield-text"
                        onClick={() => {
                          addLocation(index);
                        }}
                        style={{
                          height: "40px",
                          // marginLeft: "25px",
                          display: "flex",
                          // marginRight: "43px",
                          alignItems: "center",
                        }}
                      >
                        <i className="fal fa-plus"></i>
                      </a>
                      {index !== 0 && (
                        <a
                          className="classifield-text"
                          onClick={() => {
                            deleteLocation(index);
                          }}
                          style={{
                            height: "40px",
                            // marginLeft: "25px",
                            display: "flex",
                            // marginRight: "43px",
                            alignItems: "center",
                          }}
                        >
                          <i className="fal fa-trash"></i>
                        </a>
                      )}
                    </div>
                  </>
                );
              })}
            </>
          )}
          <p className="mt-2">
            (You must enter at least one location and please fill out all
            details in the event location.)
          </p>
          <div className="col-lg-12" style={{ marginTop: "20px" }}>
            <TextEditor
              touched={touched?.details}
              error={errors?.details}
              setFieldValue={setFieldValue}
              values={values?.details}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={isDisable}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Submit"}
        </button>
      </form>
      <LocationModal
        show={locationModal}
        handleClose={() => setlocationModal(false)}
        setshow={setlocationModal}
        getNewLocation={getNewLocation}
      />
    </div>
  );
};

export default ClassiFieldForm;
