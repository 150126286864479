import React from "react";

const Pagination = ({ links, setpage }) => {
  return (
    <div className="b-pagination-outer pt-4 pb-4">
      <ul id="border-pagination">
        {/* <li>
          <a className="" >
            <i className="fas fa-chevron-left" />
          </a>
        </li> */}

        {links?.map((x, i) => (
          <li
            key={i}
            onClick={(e) => {
              e.preventDefault();
              if (x?.url) {
                setpage(x?.url?.split("page=")[1]);
              }
            }}
          >
            <a className={` ${x?.active && "active"} `}>
              {x?.label
                .replace(/&laquo; Previous/g, "<")
                .replace(/Next &raquo;/g, ">")}
            </a>
          </li>
        ))}
        {/*        
        <li>
          <a >
            <i className="fas fa-chevron-right" />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default Pagination;
