import React from "react";
import useToggle from "../../../../Hooks/useToggle";
import MainHeader from "./MainHeader";
import TopHeader from "./TopHeader";
import { useLocation } from "react-router-dom";

const HeaderOne = ({ drawerAction }) => {
  const [show, setValue] = useToggle(false);
  const location = useLocation();
  return (
    <>
      {/* <Search
        className={`${show ? "open" : ""}`}
        searchAction={setValue.toggle}
      /> */}
      {/* <TopHeader /> */}
      <MainHeader searchAction={setValue.toggle} drawerAction={drawerAction} />
      {location?.pathname !== "/" && <div style={{ paddingTop: "60px" }}></div>}
    </>
  );
};

export default HeaderOne;
