import React from "react";
import { formatDate, formateMobileNumber } from "../../lib/constant";
import iconAddress from "../../assets/images/icon/icon_location.svg";
import iconUrl from "../../assets/images/icon/icon_web.svg";
import iconPhone from "../../assets/images/icon/icon_phone.svg";

const BussinessDetails = ({ data }) => {
  return (
    <div className="row doct_main restrict-copy">
      <div className="col-sm-12">
        <div className="business_details">
          {data?.image && (
            <img className="card-img-top" src={data?.image} alt="business" />
          )}
          <h4>{data?.name}</h4>
          <label>
            <i className="far fa-calendar" /> {formatDate(data?.start_date)}
          </label>
          <p
            // className="line-clump-2"
            style={{ cursor: "default" }}
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <a
          className="cd_detales cd_tab1"
          href={`tel:+${data?.phone_number}`}
          target="_blank"
        >
          <img src={iconPhone} alt="icon" />
          <label className="cd_mtb">
            <b>Mobile</b>
            {formateMobileNumber(data?.phone_number) || ""}
          </label>
        </a>
      </div>
      <div className="col-sm-6">
        <a href={data?.url} target="_blank" className="cd_detales cd_tab2">
          <img src={iconUrl} alt="icon" />
          <label className="cd_mtb">
            <b>Web</b>
            {data.url || ""}
          </label>
        </a>
      </div>
      <div className="col-sm-12">
        <a className="cd_detales cd_tab3">
          <img src={iconAddress} alt="icon" />
          <label className="cd_mtb">
            <b>Address</b>
            {data?.address}
            <br /> {data?.city?.city}
            {data?.state?.state}
            {data?.zip_code}
          </label>
        </a>
      </div>
    </div>
  );
};

export default BussinessDetails;
