import React, { useMemo, useState } from "react";
import FullCalender from "./FullCalender";
import { formatDate, formatDateToMMYYYY } from "../../lib/constant";
import ReactDatePicker from "react-datepicker";

const NewCalendar = ({
  eventCatData,
  data,
  selectedMonth,
  setselectedMonth,
  selectedEventCat,
  setselectedEventCat,
}) => {
  const [selectedDate, setselectedDate] = useState();

  const monthManipulater = (eventDate) => {
    const date = new Date(eventDate);
    // Extract the month and year
    const month = date?.getMonth() + 1; // Months are zero-based (0 = January)
    const year = date?.getFullYear();

    // Format to "MM/YYYY"
    const formattedDate = `${month.toString().padStart(2, "0")}/${year}`;

    return formattedDate;
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weekday = days[new Date()?.getDay()];

  const renderCalender = useMemo(() => {
    return (
      <FullCalender
        key={data?.length}
        data={data}
        selectedDate={selectedDate}
        selectedMonth={selectedMonth}
        setselectedMonth={(e) => setselectedMonth(formatDateToMMYYYY(e))}
      />
    );
  }, [data, selectedMonth, selectedDate]);

  return (
    <div className="container-fluid">
      <div className="bg_white event_main">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="calendar_filter">
                <h3>
                  <span>{weekday}</span>
                  {formatDate(new Date())}
                </h3>
                <label>Search for an event</label>
                {/* <input
                  type="date"
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value) {
                      setselectedMonth(monthManipulater(value));
                      setselectedDate(value);
                    } else {
                      // Reset values when cleared
                      setselectedMonth(null);
                      setselectedDate(null);
                    }
                  }}
                  name="date"
                /> */}
                <div className="date-picker">
                  <ReactDatePicker
                    // dateFormat="MMMM yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    // yearDropdownItemNumber={100} // Show
                    showMonthDropdown
                    autoComplete="off"
                    placeholderText="Select Date"
                    selected={selectedDate}
                    // onChange={(e) => {
                    //   setselectedMonth(e);
                    // }}
                    onChange={(e) => {
                      const value = e;
                      if (value) {
                        setselectedMonth(monthManipulater(value));
                        setselectedDate(value);
                      } else {
                        setselectedMonth(null);
                        setselectedDate(null);
                      }
                    }}
                    name="date"
                  />
                </div>
                <select
                  placeholder="Data"
                  name="country"
                  value={selectedEventCat}
                  onChange={(e) => setselectedEventCat(e?.target?.value)}
                  className="category"
                >
                  <option value="">Select Category</option>
                  {eventCatData?.map((x) => (
                    <option value={x?.id}>{x?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-9">
              {/* <div id="calendar"></div> */}
              {/* <FullCalender
                data={data}
                selectedMonth={selectedMonth}
                setselectedMonth={(e) =>
                  setselectedMonth(formatDateToMMYYYY(e))
                }
              /> */}
              {renderCalender}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCalendar;
