import React, { useEffect, useState, Suspense, useMemo } from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import { toast } from "react-toastify";
import { getBannerIMG, getSponsorIMG } from "../../services/HomeServices";
import { isImageOrVideo } from "../../lib/constant";
import { motion } from "framer-motion";
import Loader from "../../containers/Loader";
import MessageCenter from "../NewDesign/MessageCenter";
import Megaphone from "../NewDesign/Megaphone";
import AdSlider from "../NewDesign/AdSlider";
import EventDesign from "../NewDesign/EventDesign";
import JobDesign from "../NewDesign/JobDesign";
import DoctorDesign from "../NewDesign/DoctorDesign";
import ClassifiedDesign from "../NewDesign/ClassifiedDesign";
import PodcastDesign from "../NewDesign/PodcastDesign";
import FooterDesign from "../NewDesign/FooterDesign";

// Lazy-loaded components
const BannerPoster = React.lazy(() =>
  import("../Partials/Headers/HeaderOne/BannerPoster")
);

const HomeOne = () => {
  const navigationData = navigations;
  const [isLoading, setisLoading] = useState(false);
  const [drawer, setDrawer] = useToggle(false);
  const [bannerImage, setbannerImage] = useState([]);

  const getBannerImage = async () => {
    setisLoading(true);
    await getBannerIMG()
      .then((response) => {
        const array =
          response?.data?.data?.map((a) => ({
            ...a,
            isImage: isImageOrVideo(a?.image) === "image",
          })) || [];

        setisLoading(false);
        setbannerImage(array);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  useMemo(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    getBannerImage();
  }, []);

  return (
    <motion.div style={{ overflow: "hidden" }}>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>

      <Suspense
        fallback={
          <div
          // style={{
          //   minHeight: "300px",
          //   display: "flex",
          //   alignItems: "center",
          // }}
          >
            <Loader />
          </div>
        }
      >
        <div
          className="container-fluid banner_bg"
          // style={{minHeight:'90vh'}}
        >
          <BannerPoster data={bannerImage} isLoadingBanner={isLoading} />
        </div>
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <MainPostsContext.Provider>
          <MessageCenter />
        </MainPostsContext.Provider>
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <Megaphone />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <AdSlider />

        <EventDesign isHomepage={true} />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <JobDesign />
        <DoctorDesign />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <ClassifiedDesign />
        <AdSlider />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        }
      >
        <PodcastDesign />
        <FooterDesign />
      </Suspense>

      <BackToTop />
    </motion.div>
  );
};

export default HomeOne;
