import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navigation from "../../../Helpers/Navigation";
import { isLogin } from "../../../../lib/constant";
import DropDown from "../../../Helpers/DropDown";
import PopupModal from "../../../../containers/PopupModal";
import { toast } from "react-toastify";
import { logoutApi } from "../../../../services/AuthServices";
import { useGlobalStore } from "../../../../lib/store";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

const MainHeader = ({ drawerAction, searchAction }) => {
  const [searchBarMobileActive, setSearchBarMobileActive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const { search, setSearch } = useGlobalStore();
  const [isSticky, setIsSticky] = useState(false);
  const inputRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 40) {
      // 100px or adjust as needed
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logOutClicked = async (e) => {
    e.preventDefault();
    localStorage.removeItem("is_authenticated");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    isLogin();
    navigate("/");
    setModalIsOpen(false);
    toast("Logout Successfully", { type: "success" });

    // await logoutApi()
    //   .then(() => {
    //     localStorage.removeItem("is_authenticated");
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("userId");
    //  toast("Logout Successfully", { type: "success" });
    //     isLogin();
    //  setModalIsOpen(false);
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     if (error?.response?.status == 422)
    //       toast(error.response.data.error, { type: "error" });
    //     else if (error?.response?.status == 500)
    //       toast(error.response.data.message, { type: "error" });
    //     else toast("Something went wrong", { type: "error" });
    //   });
  };
  const handleButtonClick = () => {
    // Focus on the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <>
      <header id="page-top">
        <nav
          className="navbar navbar-expand-lg navbar-light fixed-top"
          style={{ zIndex: "999" }}
          aria-label="Eleventh navbar example"
        >
          <div className="container" style={{ padding: "0px !important" }}>
            {" "}
            <Link className="navbar-brand page-scroll hidden-xs" to="/">
              <img src="img/azindia_logo.svg" alt="azindia" />
            </Link>
            <button
              className="navbar-toggler collapsed nav_ralign"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample09"
              aria-controls="navbarsExample09"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
            <div
              className="navbar-collapse collapse nav_ralign"
              id="navbarsExample09"
            >
              <ul
                className="navbar-nav me-auto mb-2 mb-lg-0"
                style={{ display: "contents" }}
              >
                <li className="nav-item dropdown">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/calender"
                    id="dropdown09"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Events
                  </Link>{" "}
                  <ul className="dropdown-menu" aria-labelledby="dropdown09">
                    <li>
                      <Link className="dropdown-item" to="/calender">
                        Event Calendar
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/event-post">
                        Post An Event
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/classifieds"
                    id="dropdown09"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Classifieds
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="dropdown09">
                    <li>
                      <Link className="dropdown-item" to="/classifieds">
                        View Classifieds
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/classifield-post">
                        Post Classified AD
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/jobs"
                    id="dropdown10"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Jobs Board
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="dropdown10">
                    <li>
                      <Link className="dropdown-item" to="/jobs">
                        View Jobs Board
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/jobs-post">
                        Post Jobs Board
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    id="dropdown11"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Directory
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="dropdown10">
                    <li>
                      <Link className="dropdown-item" to="/local-bussiness">
                        Local Businesses
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctor">
                        Doctors
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/religious-places">
                        Religious Places
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    id="dropdown12"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Local
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="dropdown10">
                    {/*   <li>
                      <Link className="dropdown-item" to="/top-stories">
                        Top Stories
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/articles">
                        AZ india times
                      </Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/megaphone">
                        Megaphone
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/message-center">
                        Message Center
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/podcast">
                        Podcast
                      </Link>
                    </li>
                    {/*  <li>
                      <Link className="dropdown-item" to="/places-to-visit">
                        Place to visit
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/things-to-do">
                        Thing to Do
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </ul>
              <form
                id="custom-search"
                onSubmit={(e) => {
                  e?.preventDefault();
                  navigate("/search");
                }}
              >
                <input
                  ref={inputRef}
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                  }}
                  className="search-query"
                />
                <button
                  type="button"
                  style={{ padding: "2px" }}
                  onClick={handleButtonClick}
                >
                  {" "}
                  <i className="fa fa-search" aria-hidden="true"></i>{" "}
                </button>
                <input type="submit" style={{ display: "none" }} />
              </form>
              {!isLogin() ? (
                <Link to="/login" className="btn_signin">
                  Sign In
                </Link>
              ) : (
                <li className="nav-item dropdown d-flex">
                  {" "}
                  <Link
                    className="nav-link dropdown-toggle"
                    id="dropdown10"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white" }}
                  >
                    Profile
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="dropdown10">
                    <li>
                      <Link className="dropdown-item" to="/account-detail">
                        Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={() => setModalIsOpen(true)}
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </div>
          </div>
          <PopupModal
            title="Are you sure you want to Logout ?"
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            yesClicked={logOutClicked}
          />
        </nav>
      </header>
    </>
  );
};

export default MainHeader;
