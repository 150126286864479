import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const TextEditor = ({
  touched,
  error,
  setFieldValue,
  values,
  maxLength = 2000,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [charCount, setCharCount] = useState(0);

  // Convert content to HTML
  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  useEffect(() => {
    setFieldValue("details", convertContentToHTML());
  }, [editorState]);

  const editorStateChange = (state) => {
    const rawContent = convertToRaw(state.getCurrentContent());
    const text = rawContent.blocks.map((block) => block.text).join(""); // Extract text

    if (text.length <= maxLength) {
      setEditorState(state);
      setCharCount(text.length);
    }
  };

  return (
    <>
      <div>Details*</div>
      <div>
        <Editor
          editorState={editorState}
          wrapperStyle={{
            border: "1px solid #ccc",
          }}
          editorStyle={{
            height: "350px",
            overflow: "auto",
            backgroundColor: "white",
            padding: "10px",
          }}
          toolbarClassName="toolbarClassName"
          onEditorStateChange={editorStateChange}
        />
      </div>
      <p>
        Character Count: {charCount} / {maxLength}
      </p>
      {charCount >= maxLength && (
        <p style={{ color: "red" }}>Character limit reached!</p>
      )}
      {touched && error && <span className="text-danger">{error}</span>}
    </>
  );
};

export default TextEditor;
