import React, { useEffect, useState } from "react";
import EventCart from "./PostCards/EventCart";
import { useNavigate } from "react-router-dom";
import {  getLocalBussinessData } from "../services/HomeServices";
import { formatDate } from "../lib/constant";
import { toast } from "react-toastify";
import Loader from "./Loader";

const LocalBussinessModule = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getLocalBussinessData()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div
      className="binduz-er-sidebar-latest-post"
      // style={{ marginTop: "45px" }}
    >
      <div
        className="binduz-er-sidebar-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 className="binduz-er-title" style={{ cursor: "pointer" }}>
          LOCAL BUSINESS
        </h4>
        {/* <h2
          className="binduz-er-title"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/local-bussiness")}
        >{`CALENDER>`}</h2> */}
      </div>
      <div
        className="binduz-er-sidebar-latest-post-box"
        style={{ paddingRight: "15px", paddingLeft: "15px" }}
      >
        {!isLoading ? (
          <>
            {data?.slice(0, 5).map((x) => {
              return (
                <EventCart
                  title={x?.name}
                  date={formatDate(x?.start_date)}
                  location={x?.address}
                  image={x?.image}
                  isLocalBussiness={true}
                />
              );
            })}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default LocalBussinessModule;
