import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MessageModule from "../../containers/MessageModule";
import TopStories from "../../containers/TopStories";
import EventModule from "../../containers/EventModule";
import _ from "lodash";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { globalSearch } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import { useGlobalStore } from "../../lib/store";
import { Overlay, Tooltip } from "react-bootstrap";

const MainSection = () => {
  const [data, setdata] = useState({});
  const [isEmpty, setisEmpty] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const { search, setSearch } = useGlobalStore();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [docShow, setdocShow] = useState(false);
  const docTarget = useRef(null);

  const navigate = useNavigate();


  useEffect(() => {
 setTimeout(() => {
   getData();
 }, 500);
  }, [search]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    return () => {
      setSearch("");
    };
  }, []);

  const manipulateData = (data) => {
    const megaphoneArray =
      data?.megaphone?.map((a) => ({ ...a, message: a?.content })) || [];
    const businessArray =
      data?.business?.map((a) => ({ ...a, message: a?.description })) || [];
    const jobArray =
      data?.job?.map((a) => ({ ...a, message: a?.description })) || [];
    const toggle = Object.values(data).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );

    setisEmpty(toggle);

    setdata({
      ...data,
      megaphone: megaphoneArray,
      business: businessArray,
      job: jobArray,
    });
  };

  const getData = async () => {
    setisLoading(true);
    await globalSearch(search)
      .then((response) => {
        manipulateData(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          {/* <div className="binduz-er-top-news-title">
            <h3 className="binduz-er-title">
              <i
                className="far fa-search"
                style={{ color: "red", marginRight: "5px" }}
              ></i>
              Global Search
            </h3>
          </div> */}
          <div className="binduz-er-author-sidebar-search-bar mb-5">
            <form
              onSubmit={(e) => {
                e?.preventDefault();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                className="binduz-er-input-box "
                // style={{ width: "570px" }}
              >
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                  placeholder="Global Search..."
                  style={{ lineHeight: "50px" }}
                />
                <button type="submit" style={{ height: "52px" }}>
                  <i className="fal fa-search"></i>
                </button>
              </div>
              {/* <span className="mt-1">
                (Note :- You need to enter at least five words to search.)
              </span> */}
            </form>
          </div>
          {!isLoading ? (
            <>
              {!isEmpty ? (
                <>
                  {!_.isEmpty(data?.message) && (
                    <div className="col-lg-4 mb-5  ">
                      <div
                        className="binduz-er-top-news-title"
                        style={{ paddingLeft: "40px" }}
                      >
                        <h3 className="binduz-er-title">
                          <i
                            className="far fa-envelope-square"
                            style={{ color: "red", marginRight: "5px" }}
                          ></i>
                          MESSAGE CENTER
                        </h3>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <a
                          className="classifield-text "
                          style={{
                            height: "40px",
                            marginLeft: "25px",
                            display: "flex",
                            marginRight: "43px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate("/message-center");
                          }}
                        >
                          {"VIEW ALL >>"}
                        </a>
                      </div>
                      <MessageModule data={data?.message} isSearch={true} />
                    </div>
                  )}
                  {!_.isEmpty(data?.megaphone) && (
                    <div className="col-lg-4 mb-5 ">
                      <div
                        className="binduz-er-top-news-title"
                        style={{ paddingLeft: "40px" }}
                      >
                        <h3 className="binduz-er-title">
                          <i
                            className="far fa-envelope-square"
                            style={{ color: "red", marginRight: "5px" }}
                          ></i>
                          MEGAPHONE
                        </h3>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <a
                          className="classifield-text "
                          style={{
                            height: "40px",
                            marginLeft: "25px",
                            display: "flex",
                            marginRight: "43px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate("/megaphone");
                          }}
                        >
                          {"VIEW ALL >>"}
                        </a>
                      </div>
                      <MessageModule
                        data={data?.megaphone}
                        isSearch={true}
                        isMegaphone={true}
                      />
                    </div>
                  )}

                  {!_.isEmpty(data?.business) && (
                    <div className="col-lg-4 mb-5 ">
                      <div
                        className="binduz-er-top-news-title"
                        style={{ paddingLeft: "40px" }}
                      >
                        <h3 className="binduz-er-title">
                          <i
                            className="far fa-envelope-square"
                            style={{ color: "red", marginRight: "5px" }}
                          ></i>
                          LOCAL BUSINESS
                        </h3>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <a
                          className="classifield-text "
                          style={{
                            height: "40px",
                            marginLeft: "25px",
                            display: "flex",
                            marginRight: "43px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate("/local-bussiness");
                          }}
                        >
                          {"VIEW ALL >>"}
                        </a>
                      </div>
                      <MessageModule
                        data={data?.business}
                        isSearch={true}
                        isBusiness={true}
                      />
                    </div>
                  )}
                  {!_.isEmpty(data?.classified) && (
                    <div className=" col-lg-4 mb-5  ">
                      <div
                        className="binduz-er-top-news-title d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 className="binduz-er-title">CLASSIFIEDS</h3>
                        <i
                          ref={target}
                          onMouseEnter={() => setShow(true)}
                          onMouseLeave={() => setShow(false)}
                          onClick={() => navigate("/classifieds")}
                          className="fal fa-arrow-right"
                          style={{
                            marginRight: "18px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        ></i>
                        <Overlay
                          target={target.current}
                          show={show}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              VIEW CLASSIFIEDS
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                      <div
                        className="binduz-er-social-list "
                        style={{ height: "450px", overflow: "scroll" }}
                      >
                        <div className="binduz-er-list">
                          {data?.classified?.map((x) => (
                            <motion.div>
                              <Link
                                className="category-search"
                                to={`/classifield-detail/${x?.id}`}
                              >
                                <span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    className="line-clump-2"
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: x?.description,
                                      }}
                                    />
                                  </span>
                                </span>
                              </Link>
                            </motion.div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(data?.doctor) && (
                    <div className=" col-lg-4 mb-5  ">
                      <div
                        className="binduz-er-top-news-title d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 className="binduz-er-title">DOCTORS</h3>
                        <i
                          onClick={() => navigate("/doctor")}
                          ref={docTarget}
                          onMouseEnter={() => setdocShow(true)}
                          onMouseLeave={() => setdocShow(false)}
                          className="fal fa-arrow-right"
                          style={{
                            marginRight: "18px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        ></i>
                        <Overlay
                          target={docTarget.current}
                          show={docShow}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              VIEW DOCTORS
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                      <div
                        className="binduz-er-social-list "
                        style={{ height: "450px", overflow: "scroll" }}
                      >
                        <div className="binduz-er-list">
                          {data?.doctor?.map((x) => (
                            <motion.div>
                              <Link
                                className="category-search"
                                to={`/doctor/${x?.id}`}
                                state={x}
                              >
                                <span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    className="line-clump-2"
                                  >
                                    <div
                                      style={{ minHeight: "45px" }}
                                      dangerouslySetInnerHTML={{
                                        __html: x?.comment,
                                      }}
                                    />
                                  </span>
                                </span>
                              </Link>
                            </motion.div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(data?.topStory) && (
                    <div className="col-lg-4 mb-5 ">
                      <TopStories
                        title="TOP STORIES"
                        isSearch={true}
                        sideTitle="VIEW ALL>"
                        data={data?.topStory?.slice(0, 4)}
                        onClick={(e) =>
                          navigate(`/details/${e?.id}`, {
                            state: e,
                          })
                        }
                        onClickHeader={() => navigate("/top-stories")}
                      />
                    </div>
                  )}
                  {!_.isEmpty(data?.event) && (
                    <div className="col-lg-4 mb-5 ">
                      <EventModule data={data?.event} isSearch={true} />
                    </div>
                  )}
                  {!_.isEmpty(data?.job) && (
                    <div className="col-lg-4 mb-5  ">
                      <div
                        className="binduz-er-top-news-title"
                        style={{ paddingLeft: "40px" }}
                      >
                        <h3 className="binduz-er-title">
                          <i
                            className="far fa-envelope-square"
                            style={{ color: "red", marginRight: "5px" }}
                          ></i>
                          JOBS
                        </h3>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <a
                          className="classifield-text "
                          style={{
                            height: "40px",
                            marginLeft: "25px",
                            display: "flex",
                            marginRight: "43px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate("/jobs");
                          }}
                        >
                          {"VIEW ALL >>"}
                        </a>
                      </div>
                      <MessageModule
                        isSearch={true}
                        data={data?.job}
                        isJob={true}
                      />
                    </div>
                  )}
                </>
              ) : (
                <h3
                  style={{
                    minHeight: "380px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Data Found
                </h3>
              )}
            </>
          ) : (
            <div
              style={{
                minHeight: "380px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MainSection;
