import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"; // Import CSS file
import { getCityData, getStateData } from "../../services/AuthServices";

const libraries = ["places"];

const Popup = ({ show, setshow, onClose, getNewLocation }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const handeStateChange = (stateId) => {
    formik.setFieldValue("state", stateId);
    formik.setFieldValue("city", "");
    let code;
    for (let a of stateData) {
      if (a?.id == Number(stateId)) {
        code = a?.state_code;
      }
    }
    getCity(code);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Replace with your API key
    libraries,
  });

  const formik = useFormik({
    initialValues: {
      locationName: "",
      address: "",
      state: "",
      city: "",
      zipCode: "",
      latitude: "10.10",
      longitude: "10.10",
    },
    validationSchema: Yup.object({
      locationName: Yup.string().required("Location Name is required"),
      address: Yup.string().required("Address is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      zipCode: Yup.string()
        .matches(/^\d{5}$/, "Enter a valid 5-digit ZIP Code")
        .required("ZIP Code is required"),
    }),
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      getNewLocation(values);
      setshow(false);
      formik.resetForm();
    },
  });

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      formik.setFieldValue("address", place.formatted_address);
    }
  };

  return (
    <Modal show={show} onHide={() => setshow(false)} centered fade>
      <Modal.Header closeButton>
        <Modal.Title>Create Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          {/* Location Name */}
          <Form.Group className="mb-3">
            <Form.Label>Location Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location Name"
              {...formik.getFieldProps("locationName")}
            />
            {formik.touched.locationName && formik.errors.locationName ? (
              <div className="text-danger">{formik.errors.locationName}</div>
            ) : null}
          </Form.Group>

          {/* Type */}

          {/* Address (Google Autocomplete) */}
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            {isLoaded && (
              <Autocomplete
                onLoad={setAutocomplete}
                onPlaceChanged={handlePlaceSelect}
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  {...formik.getFieldProps("address")}
                />
              </Autocomplete>
            )}
            {formik.touched.address && formik.errors.address ? (
              <div className="text-danger">{formik.errors.address}</div>
            ) : null}
          </Form.Group>

          {/* State */}
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <Form.Select
              //   {...formik.getFieldProps("state")}
              onChange={(e) => {
                handeStateChange(e?.target?.value);
              }}
            >
              <option value="">Please Select state ...</option>
              {stateData?.map((x) => (
                <option value={x?.id}>{x?.state}</option>
              ))}
            </Form.Select>
            {formik.touched.state && formik.errors.state ? (
              <div className="text-danger">{formik.errors.state}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Select {...formik.getFieldProps("city")}>
              <option value="">Please Select city ...</option>
              {cityData?.map((x) => (
                <option value={x?.id}>{x?.city}</option>
              ))}
            </Form.Select>
            {formik.touched.city && formik.errors.city ? (
              <div className="text-danger">{formik.errors.city}</div>
            ) : null}
          </Form.Group>

          {/* Zip Code */}
          <Form.Group className="mb-3">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Zip Code"
              {...formik.getFieldProps("zipCode")}
            />
            {formik.touched.zipCode && formik.errors.zipCode ? (
              <div className="text-danger">{formik.errors.zipCode}</div>
            ) : null}
          </Form.Group>

          {/* Buttons */}
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              onClick={() => setshow(false)}
              className="me-2"
            >
              Close
            </Button>
            <Button type="submit" className="btn btn-primary">
              Add Location
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Popup;
