import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import "./index.css";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

const OTPpopUp = ({
  title,
  verifyEmail,
  modalIsOpen,
  setModalIsOpen,
  yesClicked,
}) => {
  //   const closeModal = () => setModalIsOpen(false);
  const [otp, setOtp] = useState("");
  const initialTime = 30;

  // State variables
  const [seconds, setSeconds] = useState(initialTime); // Seconds countdown
  const [isDisabled, setIsDisabled] = useState(true); // Disable resend button

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      // Clean up the interval on component unmount or when the timer reaches zero
      return () => clearInterval(timerId);
    } else {
      setIsDisabled(false); // Enable the resend button when the countdown reaches 0
    }
  }, [seconds]);

  const handleResend = () => {
    // Reset the timer back to 2 minutes
    verifyEmail();
    setSeconds(initialTime);
    setIsDisabled(true);
  };

  // Format the time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleChange = (data) => {
    setOtp(data);
  };

  const submitClicked = () => {
    if (otp?.length === 6 && Number(otp)) {
      yesClicked(otp);
    } else {
      toast("Please enter proper OTP", { type: "error" });
    }
  };

  return (
    <Modal
      style={{ width: "80px" }}
      isOpen={modalIsOpen}
      //   onRequestClose={closeModal}
      contentLabel="Example Modal"
      className="custom-modal otp-width"
      overlayClassName="custom-modal-overlay"
    >
      <h5>Enter Verification Code</h5>
      {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
        inputStyle="otp-input" // Custom class for input styling
        focusStyle="otp-input-focus" // Class for focused input
        containerStyle="otp-input-container" // Class for input container
      />
      {/* </div> */}
      <div
        className="button-section"
        style={{ justifyContent: "space-between" }}
      >
        <div>
          {seconds !== 0 && (
            <span
              style={{
                color: "#FF6821",
              }}
            >
              {formatTime(seconds)}
            </span>
          )}
          <br />
          <span
            style={{
              cursor: "pointer",
              color: isDisabled ? "gray" : "#FF6821",
              // textDecoration: "underline",
            }}
            onClick={!isDisabled && handleResend}
            disabled={isDisabled}
          >
            Resend OTP
          </span>
        </div>
        <div>
          <button
            onClick={submitClicked}
            className="close-modal-button"
            style={{ marginRight: "8px" }}
          >
            Submit
          </button>
          <button onClick={() => setOtp("")} className="close-modal-button">
            Clear
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OTPpopUp;
