import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/azlogomessage.jpeg";
import eventLogo from "../../assets/images/event.png";
import { motion } from "framer-motion";

// import dateFormat from "../../../lib/dateFormat";

function EventCart({
  title,
  image,
  date,
  location,
  onClick,
  isLocalBussiness,
}) {
  let imagePath;

  if (isLocalBussiness) {
    imagePath = image || img;
  } else {
    imagePath = image || eventLogo;
  }

  let widthOfPage = window.innerWidth > 700;

  return (
    <motion.div
      className={`binduz-er-sidebar-latest-post-item ${!widthOfPage && "row"}`}
      style={{ marginBottom: "5px", display: "flex", justifyContent: "center" }}
      whileHover={{ scale: 1.1 }} // Scale up the image on hover
      transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
    >
      <div
        className="binduz-er-thumb col-lg-3"
        style={{ marginRight: "0px", overflow: "visible" }}
      >
        <motion.img
          src={imagePath}
          alt="latest"
          width={70}
          style={{
            minHeight: "50px",
            minWidth: "50px",
            // padding: "10px",
            borderRadius: "18px",
          }}
          height={70}
          initial={{ scale: 1 }} // Initial scale
          animate={{ scale: 1 }} // Keep the scale at 1 when not hovering
        />
      </div>
      <div className="binduz-er-content col-lg-9">
        <span style={{ color: "black" }}>
          <i className="fal fa-calendar-alt"></i> {date}
        </span>
        <h4
          className="binduz-er-title top-stories-list-line"
          style={{ cursor: !isLocalBussiness ? "pointer" : "" }}
          onClick={onClick}
        >
          <a>{title}</a>
        </h4>
        <span className="top-stories-list-line">
          <Link>{location}</Link>
        </span>
      </div>
    </motion.div>
  );
}

export default EventCart;
