import React, { useState, useRef, useEffect } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import { Link } from "react-router-dom";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import { getJobsData } from "../../services/HomeServices";
import { toast } from "react-toastify";

const JobDesign = () => {
  const [data, setdata] = useState([]);
  const [position, setPosition] = useState(0); // Tracks the current position
  const sliderRef = useRef(null); // Ref to the slider container

  useEffect(() => {
     setTimeout(() => {
           getData();

     }, 700);
  }, []);

  const handleNext = () => {
    let next = position + 30;

    if (position === 70) {
      // Reset to the start
      setPosition(0);
      sliderRef.current.style.transition = "left 1s";
      sliderRef.current.style.left = "0%";
    } else {
      // Move to the next position
      setPosition(next);
      sliderRef.current.style.transition = "left 1s";
      sliderRef.current.style.left = `-${next}.5%`;
    }
  };

  const handlePrevious = () => {
    let previous = position - 30;

    if (position === 0) {
      // Reset to the end
      setPosition(70);
      sliderRef.current.style.transition = "left 1s";
      sliderRef.current.style.left = "-70%";
    } else {
      // Move to the previous position
      setPosition(previous);
      sliderRef.current.style.transition = "left 1s";
      sliderRef.current.style.left = `-${previous}.5%`;
    }
  };

  const getData = async (page, firstTimeLoad) => {
    await getJobsData("", "", page, "")
      .then((response) => {
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section id="slider" className="container-fluid bg_gray">
      <div className="container jobs_main">
        <div className="row">
          <div className="col-6">
            <h2>Jobs Board</h2>
          </div>
          <div className="col-6">
            <Link to="/jobs-post" className="btn_post">
              Post <i className="fas fa-plus" />
            </Link>
          </div>
        </div>
        <div className="area pt-4">
          <div className="row">
            <div
              className="col-sm-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <article className="content">
                <h4 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                  {/* We are hiring <span>Join our team</span> */}
                  <span style={{ color: "#ff6820" }}>Opportunities</span>
                  <span>from Local Businesses.</span>
                </h4>
                {/* <span>
                  Opportunities from Local Businesses FOR LOCAL TALENT.
                </span> */}
                <div className="slide-controls">
                  <span className="slider-previous" onClick={handlePrevious}>
                    <i className="fas fa-chevron-left" />
                  </span>
                  <span className="slider-next" onClick={handleNext}>
                    <i className="fas fa-chevron-right" />
                  </span>
                </div>
              </article>
            </div>
            <div className="col-sm-9">
              <div className="scroll-wrapper">
                <ul
                  className="row"
                  style={{ flexWrap: "initial", left: "0%" }}
                  ref={sliderRef}
                >
                  {data.map((x, index) => (
                    <li className="col-sm-4" key={index}>
                      <div className="job_card">
                        <label>
                          <i className="far fa-calendar" />{" "}
                          {formatDateToMMDDYYYY(x?.date)}
                        </label>
                        <div
                          style={{ minHeight: "80px" }}
                          className="line-clump-2"
                          dangerouslySetInnerHTML={{
                            __html: x?.description,
                          }}
                        />{" "}
                        <Link to={`/jobs-detail/${x?.id}`} state={x}>
                          More
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <Link to="/jobs" className="btn_seeall">
            See All <i className="fas fa-chevron-right" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default JobDesign;
