import React, { useState } from "react";
import Modal from "react-modal";
import img from "../../assets/images/az-logo.png";
import "./ModalStyles.css";

Modal.setAppElement("#root");

const PremiumModal = ({
  title,
  amount,
  modalIsOpen,
  descArray,
  isLifetime,
  isAccDetails,
  titleArray,
  isValidityOff,
  topLinesArray,
  lastLine,
  enrollPremiumTitle,
  setModalIsOpen,
  noClicked,
  yesClicked,
}) => {
  const closeModal = () => setModalIsOpen(false);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className="custom-premium-modal"
      overlayClassName="custom-premium-modal-overlay"
    >
      <div
      // className="card text-white bg-dark mb-3"
      // style={{ maxWidth: "18rem", borderRadius: "15px" }}
      >
        <div className="card-body">
          <h5 className="card-title d-flex align-items-center">
            <img
              src={img}
              alt="Spotify Logo"
              style={{ width: "100px", marginRight: "10px" }}
            />
          </h5>
          <h2 className="card-text" style={{ color: "#FF6821" }}>
            {title} PREMIUM {isLifetime && `FOR LIFETIME`}
          </h2>
          <p className="card-text" style={{ fontWeight: "bold" }}>
            ${amount} {!isValidityOff && "for 30 Days"}
          </p>
          <hr style={{ borderColor: "#fff" }} />
          <p>
            {topLinesArray?.map((x) => (
              <span>
                {x} <br />
              </span>
            ))}
          </p>
          <ul className="mt-2">
            {descArray?.map((x, i) => (
              <li className="m-1" style={{ fontWeight: "800" }}>
                <span style={{ fontWeight: "900", color: "#FF6821" }}>
                  {titleArray[i]}
                </span>
                {x}
              </li>
            ))}
          </ul>
          <h6>{lastLine}</h6>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "15px",
            }}
            className="col-lg-12"
          >
            {!isAccDetails ? (
              <>
                <button
                  type="button"
                  onClick={noClicked}
                  className="binduz-er-main-btn contact-us-box-button col-lg-6 "
                  style={{
                    bottom: "0px",
                    position: "relative",
                    left: "auto",
                    transform: "none",
                    // margin: "20px",

                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  GO WITH NORMAL POST
                </button>
                <button
                  type="button"
                  onClick={yesClicked}
                  className="binduz-er-main-btn contact-us-box-button col-lg-6 "
                  style={{
                    bottom: "0px",
                    position: "relative",
                    left: "auto",
                    transform: "none",
                    backgroundColor: "green",
                    // margin: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  UPGRADE YOUR {title} TO PREMIUM
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={yesClicked}
                className="binduz-er-main-btn contact-us-box-button col-lg-6 "
                style={{
                  bottom: "0px",
                  position: "relative",
                  left: "auto",
                  transform: "none",
                  backgroundColor: "green",
                  // margin: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {enrollPremiumTitle || "Enroll For Premium"}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PremiumModal;
