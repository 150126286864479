import React, { useEffect, useState } from "react";
import Pagination from "../../containers/Pagination";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPodcast } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import PodcastBox from "../../containers/PodcastBox";
import { plainString } from "../../lib/constant";

const MainSection = () => {
  let widthOfPage = window.innerWidth > 1000;

  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
   const [page, setpage] = useState(1);
    const [links, setlinks] = useState([]);

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  const getData = async () => {
    setisLoading(true);
    await getPodcast(page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
         setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const videoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+&v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="binduz-er-top-news-title">
          <h3 className="binduz-er-title">
            <i
              className="far fa-podcast"
              style={{ color: "red", marginRight: "5px" }}
            ></i>
            PODCAST
          </h3>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: !widthOfPage && "center",
          }}
        >
          <div
            // className="binduz-er-recent-news-box"
            // style={{ borderRadius: "18px" }}
            className="col-lg-12 row mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {!isLoading ? (
              <>
                {data &&
                  data.length > 0 &&
                  data?.map((obj, index) => (
                    <PodcastBox
                      key={obj.id}
                      datas={{
                        image: `https://img.youtube.com/vi/${videoId(
                          obj?.link
                        )}/sddefault.jpg`,
                        link: obj?.link,
                        title: obj?.title,
                        date: obj?.created_at,
                        description: plainString(obj?.description),
                        category: obj?.category,
                      }}
                    />
                  ))}
                <Pagination links={links} setpage={setpage} />
              </>
            ) : (
              <div
                style={{
                  minHeight: "400px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            )}
          </div>

          {/* <div className="col-lg-3">
            <SideADModule isClassifield={true} isEvent={true} />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default MainSection;
