import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const login = (data) => {
  const headers = {
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/login", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const register = (data) => {
  const headers = {
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/register", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const verifyEmailByOTP = (data) => {
  const headers = {
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/verify-email", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const verifyEmailOTP = (data) => {
  const headers = {
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/verify-otp", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const forgotPassword = (data) => {
  const headers = {
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/forgot-password", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const changePassword = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/change-password", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const messageApi = (data) => {
  const headers = {
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/contact-us", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const verifyToken = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/verify-token", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const resetPassword = (data) => {
  // let token = localStorage.getItem("token");

  const headers = {
    // Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/reset-password", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addBussiness = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-business", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addNewsLetter = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/news-letter", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const updateBussiness = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-business", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const updateMegaphone = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-megaphone", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const updateProfile = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-account", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addClassifield = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-classified", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addMegaphone = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-megaphone", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const updateClassifield = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-classified", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addJob = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-job", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addEvent = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-event", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};


const paymentRenewBusinessApi = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/paymentRenew-business", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const paymentRenewClassifiedApi = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/paymentRenew-classified", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const paymentRenewMegaphoneApi = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/paymentRenew-megaphone", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const paymentRenewEventApi = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/paymentRenew-event", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const paymentRenewJobApi = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/paymentRenew-job", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const updateEvent = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-event", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const addDoctor = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/add-doctor", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const updateJob = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/update-job", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const getStateData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/state`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getAccountData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
    //  Accept: "application/json",
    // Accept: "*/*",
    // "access-control-allow-origin": "*",
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/my-account`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getSubscriptionData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
   
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/payment-detail`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const subscriptionPayment = (data) => {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/payment", data, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldPaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/classified-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
      
  });
};

const getEventPaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/event-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getBussinessPaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/business-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getDoctorPaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/doctor-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};


const getMegaphonePaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/megaphone-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getJobPaymentSuccess = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/job-payment-update?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const renewAPIforEvent = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/paymentRenew-update-event?status=${status}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const renewAPIforMegaphone = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/paymentRenew-update-megaphone?status=${status}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const renewAPIforJob = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/paymentRenew-update-job?status=${status}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const renewAPIforBussiness = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/paymentRenew-update-business?status=${status}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const renewAPIforClassified = async (status) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/paymentRenew-update-classified?status=${status}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};



const getCityData = async (code) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/city?code=${code}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};
const logoutApi = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/logout`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");

    if (window.location.pathname != "/login") {
      window.location.href = "/#/login";
    }
  } else {
    reject(error);
  }
}

export {
  login,
  getAccountData,
  addBussiness,
  updateBussiness,
  logoutApi,
  subscriptionPayment,
  updateEvent,
  forgotPassword,
  addClassifield,
  addJob,
  getDoctorPaymentSuccess,
  getMegaphonePaymentSuccess,
  getJobPaymentSuccess,
  updateProfile,
  resetPassword,
  addMegaphone,
  paymentRenewMegaphoneApi,
  getSubscriptionData,
  messageApi,
  getEventPaymentSuccess,
  addNewsLetter,
  verifyToken,
  renewAPIforMegaphone,
  verifyEmailByOTP,
  paymentRenewBusinessApi,
  verifyEmailOTP,
  updateClassifield,
  addEvent,
  getClassifieldPaymentSuccess,
  updateMegaphone,
  paymentRenewClassifiedApi,
  changePassword,
  getBussinessPaymentSuccess,
  paymentRenewJobApi,
  paymentRenewEventApi,
  renewAPIforClassified,
  renewAPIforBussiness,
  renewAPIforJob,
  renewAPIforEvent,
  updateJob,
  register,
  getCityData,
  getStateData,
  addDoctor,
};
