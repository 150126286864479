import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import NewDoctorPage from "./NewDoctorPage";
import NewDesign from "./NewDesign";

const DoctorDetail = () => {
      const navigationData = navigations;

      useEffect(() => {
        document.body.classList.add("gray-bg");
      });
      const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      {/* <NewDoctorPage /> */}
      <NewDesign />
      <Footer />
    </>
  );
}

export default DoctorDetail