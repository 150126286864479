import React, { useRef, useState } from "react";
import { Table, Button, Overlay, Tooltip } from "react-bootstrap";
import { getDatefromBackend, plainString } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PopupModal from "../../containers/PopupModal";
import { toast } from "react-toastify";
import { paymentRenewJobApi } from "../../services/AuthServices";

const JobDetails = ({ setselectedTab, data }) => {
  const [id, setid] = useState("");
  const [renewModal, setrenewModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [renewIndex, setrenewIndex] = useState();
  // const [show, setShow] = useState(null); // Track which button is hovered
  // const [target, setTarget] = useState(null); // Track the target button ref

  const navigate = useNavigate();

  const handleEdit = (id, index) => {
    // Handle edit logic here
    navigate(`/job-update/${id}`, { state: data[index] });
  };


  const renewPayment = async () => {
    setisLoading(true);
    await paymentRenewJobApi({ id: id })
      .then((response) => {
        setisLoading(false);

        response?.data?.data?.url
          ? window.location.replace(response?.data?.data?.url)
          : toast("Something went wrong,Please try again after some time", {
              type: "error",
            });
      })
      .catch((error) => {
        setisLoading(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="btn_post mb-3"
          onClick={() => navigate("/jobs-post")}
        >{`POST JOB `} <i class="fas fa-plus"></i></h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>JobsBoard Details</th>
                <th>Start Date</th>
                {/* <th>Email</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td> {plainString(item?.description)}</td>
                  <td>{getDatefromBackend(item?.date)}</td>

                  {/* <td>{item?.emai}</td> */}
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="warning"
                        size="sm"
                        className="mr-2"
                        onClick={() => {
                          if (item?.is_expire) {
                            setrenewModal(true);
                            // setid("");
                            setid(item?.id);
                            setrenewIndex(index);
                          } else {
                            handleEdit(item?.id, index);
                          }
                        }}
                      >
                        {item?.is_expire ? (
                          "Renew"
                        ) : (
                          <i className="fal fa-edit"></i>
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PopupModal
            title="Your Premium post will expire shortly; please
                                  renew."
            modalIsOpen={renewModal}
            setModalIsOpen={setrenewModal}
            yesClicked={() => {
              renewPayment();
            }}
            yesTitle={"Renew"}
            noTitle={"Edit"}
            noTitleClicked={() => {
              handleEdit(id, renewIndex);
            }}
          />
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default JobDetails;
