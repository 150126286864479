import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Overlay, Tooltip } from "react-bootstrap";
import { getDatefromBackend, plainString } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PremiumModal from "../../containers/PremiumModal";
import { toast } from "react-toastify";
import { paymentRenewBusinessApi } from "../../services/AuthServices";
import PopupModal from "../../containers/PopupModal";

const LocalBussinessDetails = ({ setselectedTab, data, amountData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [renewModal, setrenewModal] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [id, setid] = useState("");
  const [renewIndex, setrenewIndex] = useState();
  const [show, setShow] = useState(null); // Track which button is hovered
  const [target, setTarget] = useState(null); // Track the target button ref

  const navigate = useNavigate();

  useEffect(() => {
    getAmount();
  }, []);

   const handleMouseEnter = (event, id) => {
     setShow(id); // Set the ID of the hovered button
     setTarget(event.target); // Set the target to the hovered button's DOM element
   };

   const handleMouseLeave = () => {
     setShow(null); // Reset the hover state
   };

  const getAmount = async () => {
    const event = amountData?.find((a) => a?.type === "Event");
    if (event) {
      setpremiumAmount(Number(event?.amount));
    }
  };
  const handleEdit = (id, index) => {
    // Handle edit logic here
    navigate(`/local-bussiness-update/${id}`, { state: data[index] });
  };

  const renewPayment = async () => {
    setisLoading(true);
    await paymentRenewBusinessApi({ id: id })
      .then((response) => {
        setisLoading(false);

        response?.data?.data?.url
          ? window.location.replace(response?.data?.data?.url)
          : toast("Something went wrong,Please try again after some time", {
              type: "error",
            });
      })
      .catch((error) => {
        setisLoading(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="btn_post mb-3"
          onClick={() => navigate("/local-bussiness-post")}
        >{`POST LOCAL BUSINESS `} <i class="fas fa-plus"></i></h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Local Business Information</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{plainString(item?.description)}</td>

                  <td>{item?.status ? "Active" : "Inactive"}</td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // gap: "10px",
                      }}
                    >
                      {item?.type === "Premium" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="warning"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              // if (item?.is_expire) {
                              //   setrenewModal(true);
                              //   // setid("");
                              //   setid(item?.id);
                              //   setrenewIndex(index);
                              // } else {
                              handleEdit(item?.id, index);
                              // }
                            }}
                          >
                            {/* {item?.is_expire ? (
                              "Renew"
                            ) : ( */}
                            <i className="fal fa-edit"></i>
                            {/* )} */}
                          </Button>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="warning"
                            size="sm"
                            onMouseEnter={(e) => handleMouseEnter(e, item.id)}
                            onMouseLeave={handleMouseLeave}
                            className="mr-2"
                            onClick={() => {
                              setModalIsOpen(true);
                              setid(item?.id);
                            }}
                          >
                            Get Premium
                          </Button>
                          <Overlay
                            target={target}
                            show={show === item.id}
                            placement="top"
                          >
                            {(props) => (
                              <Tooltip id={`tooltip-${item.id}`} {...props}>
                                To edit business, please upgrade to Premium
                              </Tooltip>
                            )}
                          </Overlay>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <PopupModal
              title="Your Premium post will expire shortly; please
                                  renew."
              modalIsOpen={renewModal}
              setModalIsOpen={setrenewModal}
              yesClicked={() => {
                renewPayment();
              }}
              yesTitle={"Renew"}
              noTitle={"Edit"}
              noTitleClicked={() => {
                handleEdit(id, renewIndex);
              }}
            />
            <PremiumModal
              isAccDetails={true}
              title={"LOCAL BUSINESS"}
              amount={premiumAmount}
              modalIsOpen={modalIsOpen}
              isLifetime={true}
              setModalIsOpen={setModalIsOpen}
              topLinesArray={[
                "You can list your business on our platform for free and connect with the Indian community seeking your products and services. Your free listing will help customers find the right businesses for their needs, fostering a strong, supportive community.",
                `For just $${premiumAmount}, you can upgrade your classified and unlock these exclusive benefits:`,
              ]}
              descArray={[
                " Your business will be featured in a spotlight position, enhancing visibility and attracting more customers.",
                " Upload up to 1 image making your listing more engaging and informative.",
                " Appear at the top of search results, making it easier for customers to find your business first.",
                " By being more visible, you’ll help the Indian community find and support local businesses, fostering economic growth and community ties.",
              ]}
              titleArray={[
                "Spotlight Position:-",
                "Enhanced Media Uploads:-",
                "Priority Listing:-",
                "Community Support:-",
              ]}
              lastLine={`Upgrading to Premium ensures your business stands out, attracts more attention, and helps the Indian community connect with the services and products they need. Post for free and upgrade to Premium to maximize your impact and reach within the community.`}
              yesClicked={() => {
                renewPayment();
              }}
              enrollPremiumTitle={
                isLoading ? "Loading..." : "Enroll For Premium"
              }
            />
          </Table>
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default LocalBussinessDetails;
