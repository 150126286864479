import React, { useEffect, useMemo, useState } from "react";
import "../../assets/css/tabs.css";
import { Link } from "react-router-dom";
import { getMegaPhoneData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { formatDate, minimizeName, plainString } from "../../lib/constant";
import Loader from "../../containers/Loader";
import img from "../../assets/images/azlogomessage.jpeg";

const Megaphone = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState();
  const [activeImage, setActiveImage] = useState(0);
  const [page, setpage] = useState(1);
  const [toggle, settoggle] = useState(false);
  const [lastPage, setlastPage] = useState(0);

  const ImangeHander = (index) => {
    setActiveImage(index);
  };

  const getdata = async (pagee, firstTimeLoad) => {
    firstTimeLoad && setisLoading(true);
    await getMegaPhoneData("", pagee || page, 3)
      .then((response) => {
        setpage(response?.data?.data?.current_page);
        setdata(response?.data?.data?.data);
        firstTimeLoad && setisLoading(false);
        setlastPage(response?.data?.data?.last_page);
        setActiveImage(0);
      })
      .catch((error) => {
        firstTimeLoad && setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const intervalBreak = (num) => {
    if (num + 1 === 3) {
      settoggle((prev) => !prev);
      return 0; // Reset to 0
    }
    return num + 1 >= 3 ? 0 : num + 1;
  };

  useMemo(() => getdata(page, true), []);

  useMemo(() => {
    // if (  page !== lastPage && page < lastPage + 1) {
    //   getdata(page + 1);
    //   setpage(page + 1);
    // } else {
    //   getdata(1);
    //   setpage(1);
    // }

    if (toggle) {
      getdata(2);
      setpage(2);
    } else {
      getdata(1);
      setpage(1);
    }
  }, [toggle]);

  useMemo(() => {
    let timer = setInterval(() => {
      setActiveImage((prev) => intervalBreak(prev));
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <div className="container-fluid mc_main bg_white">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h2>Megaphone</h2>
            </div>
            <div className="col-6">
              {" "}
              <Link to="/megaphone-post" className="btn_post">
                Post <i className="fas fa-plus"></i>
              </Link>{" "}
            </div>
          </div>
          <div className="row pt-4">
            <div className="tab-wrapper">
              {!isLoading ? (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="tab-body">
                      <div
                        className="tab-content"
                        style={{ width: "388px", height: "501px" }}
                      >
                        {" "}
                        <img
                          src={data[activeImage || 0]?.image||img}
                          alt="img"
                          // width={"388px"}
                          // height={"501px"}
                        />{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="tab-header">
                      {data?.map((item, index) => (
                        <div
                          className={`tab-btn ${
                            activeImage == index ? "active" : ""
                          }`}
                          onClick={() => ImangeHander(index)}
                        >
                          {" "}
                          <span>
                            <i className="far fa-calendar"></i>{" "}
                            {formatDate(item?.start_date)}
                          </span>
                          <p className="line-clump-2">
                            {minimizeName(plainString(item?.content),150)}
                          </p>
                          <Link
                            to={`/details/${item?.id}`}
                            state={item}
                          >
                            More
                          </Link>{" "}
                        </div>
                      ))}

                      <div className="underline"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    minHeight: "450px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
            <Link to="/megaphone" className="btn_seeall">
              See All <i className="fas fa-chevron-right"></i>
            </Link>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Megaphone;
