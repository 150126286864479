import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../components/Blogs/BlogOne/Breadcrumb";
import Loader from "../../containers/Loader";
import { toast } from "react-toastify";
import { getOrganizationData } from "../../services/HomeServices";
import _ from "lodash";

function MainSection() {
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");


  useEffect(() => {
 
     setTimeout(() => {
       getData();
     }, 500);
  }, [search]);

  const getData = async () => {
    setisLoading(true);
    await getOrganizationData(search)
      .then((response) => {
        setdata(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section className="binduz-er-about-us-area pb-60">
      <div className=" container">
        <div className="row">
          {/* <Breadcrumb title={"Organization"} /> */}
          <div className=" col-lg-12">
            <div
              className="binduz-er-about-us-content"
              // style={{ paddingTop: "0px" }}
            >
              <div>
                <div className="binduz-er-author-sidebar-search-bar col-lg-12 mt-5">
                  <form
                    onSubmit={(e) => {
                      e?.preventDefault();
                      getData();
                    }}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="binduz-er-input-box"
                      style={{ width: "270px" }}
                    >
                      <input
                        type="text"
                        onChange={(e) => setsearch(e?.target?.value)}
                        value={search}
                        placeholder="Search from here..."
                        style={{ lineHeight: "38px" }}
                      />
                      <button type="submit" style={{ height: "40px" }}>
                        <i className="fal fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                {!isLoading ? (
                  <div className="col-lg-12">
                    <div className="binduz-er-news-categories-tab ">
                      <ul
                        className="nav nav-pills justify-content-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        {data.map((item, index) => (
                          <li
                            key={index}
                            className="nav-item m-3"
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              href={item?.url}
                              target="_blank"
                              // onClick={(e) => tabHandler(e, catItem.id)}
                              className={`nav-link 
                              
                                m-1`}
                            >
                              {item?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
          
        </div>
       
      </div>
    </section>
  );
}

export default MainSection;
