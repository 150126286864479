import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Tooltip, Overlay } from "react-bootstrap";
import { getDatefromBackend, plainString } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PremiumModal from "../../containers/PremiumModal";
import { toast } from "react-toastify";
import { paymentRenewClassifiedApi } from "../../services/AuthServices";
import PopupModal from "../../containers/PopupModal";

const ClassifieldDetails = ({ setselectedTab, data, amountData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [renewModal, setrenewModal] = useState(false);
  // const [show, setShow] = useState(false);
  const [show, setShow] = useState(null); // Track which button is hovered
  const [target, setTarget] = useState(null); // Track the target button ref
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [id, setid] = useState("");
  const [renewIndex, setrenewIndex] = useState();
  // const target = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getAmount();
  }, []);

   const handleMouseEnter = (event, id) => {
     setShow(id); // Set the ID of the hovered button
     setTarget(event.target); // Set the target to the hovered button's DOM element
   };

   const handleMouseLeave = () => {
     setShow(null); // Reset the hover state
   };


  const getAmount = async () => {
    const event = amountData?.find((a) => a?.type === "Classifieds");
    if (event) {
      setpremiumAmount(Number(event?.amount));
    }
  };

 
  const handleEdit = (id, index) => {
    navigate(`/classifield-update/${id}`, { state: data[index] });
  };

  const renewPayment = async () => {
    setisLoading(true);
    await paymentRenewClassifiedApi({ id: id })
      .then((response) => {
        setisLoading(false);

        response?.data?.data?.url
          ? window.location.replace(response?.data?.data?.url)
          : toast("Something went wrong,Please try again after some time", {
              type: "error",
            });
      })
      .catch((error) => {
        setisLoading(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="btn_post mb-3"
          onClick={() => navigate("/classifield-post")}
        >{`POST CLASSIFIED `} <i class="fas fa-plus"></i></h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Details</th>
                <th>Start Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.type}</td>
                  <td>{plainString(item?.description)}</td>

                  <td>{getDatefromBackend(item?.start_date)}</td>
                  <td>{item?.status ? "Active" : "Inactive"}</td>

                  <td>
                    {item?.type === "Premium" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="warning"
                          size="sm"
                          className="mr-2"
                          onClick={() => {
                            if (item?.is_expire) {
                              setrenewModal(true);
                              // setid("");
                              setid(item?.id);
                              setrenewIndex(index);
                            } else {
                              handleEdit(item?.id, index);
                            }
                          }}
                        >
                          {item?.is_expire ? (
                            "Renew"
                          ) : (
                            <i className="fal fa-edit"></i>
                          )}
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="warning"
                          size="sm"
                          onMouseEnter={(e) => handleMouseEnter(e, item.id)}
                          onMouseLeave={handleMouseLeave}
                          className="mr-2"
                          onClick={() => {
                            setModalIsOpen(true);
                            setid(item?.id);
                          }}
                        >
                          Get Premium
                        </Button>
                        <Overlay
                          target={target}
                          show={show === item.id}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id={`tooltip-${item.id}`} {...props}>
                              To edit classified, please upgrade to Premium
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PopupModal
            title="Your Premium post will expire shortly; please
                                  renew."
            modalIsOpen={renewModal}
            setModalIsOpen={setrenewModal}
            yesClicked={() => {
              renewPayment();
            }}
            yesTitle={"Renew"}
            noTitle={"Edit"}
            noTitleClicked={() => {
              handleEdit(id, renewIndex);
            }}
          />
          <PremiumModal
            title={"CLASSIFIED"}
            amount={premiumAmount}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            topLinesArray={[
              "You can post your classifieds for free on our platform, and they will be valid for 15 days.Enjoy the convenience and reach of our service without any cost!",
              `For just $${premiumAmount}, you can upgrade your classified and unlock these exclusive benefits:`,
            ]}
            descArray={[
              " Your classified will be active for 30 days, giving you double the exposure.",
              " Your classified will be moved to a spotlight position, ensuring better visibility and traction.",
              " Upload up to 4 images, making your classified more attractive and informative.",
              " Your classified will appear at the top of search results, catching the eye of potential customers first.",
            ]}
            titleArray={[
              "Extended Visibility:-",
              "⁠Spotlight Position:-",
              "Enhanced Media Uploads:-",
              "Priority Listing:-",
            ]}
            lastLine={`Upgrading to Premium ensures your classified stands out, attracts more attention, and reaches the right audience effectively. Post for free and upgrade to Premium to maximize your results!`}
            isAccDetails={true}
            yesClicked={() => {
              renewPayment();
            }}
            enrollPremiumTitle={isLoading ? "Loading..." : "Enroll For Premium"}
          />
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default ClassifieldDetails;
