import React, { useCallback, useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getMegaPhoneData } from "../../services/HomeServices";
import { formatDate, formatDateToMMYYYY } from "../../lib/constant";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import _ from "lodash";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  useEffect(() => {
    setpage(1);
    setTimeout(() => {
      getData();
    }, 500);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [search, selectedMonth]);

  const getData = async () => {
    setisLoading(true);
    await getMegaPhoneData(search, page, 10, formatDateToMMYYYY(selectedMonth))
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">
                <i
                  className="far fa-bullhorn"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                MEGAPHONE
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="date-picker">
                    <ReactDatePicker
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      selected={selectedMonth}
                      onChange={(e) => {
                        setselectedMonth(e);
                      }}
                      autoComplete="off"
                      name="date"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => setsearch(e?.target?.value?.trim())}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((item, index) => (
                      <CardRowModule
                        key={item.id}
                        datas={{
                          data: {
                            ...item,
                            isMegaphone: true,
                            date: item?.start_date,
                          },
                          image: item?.image,
                          category: item?.title,
                          index: index,
                          isTopStories: true,
                          title: item?.content,
                          imageSize: "130px",
                          pathname: `/details/${item?.id}`,
                          onClickFun: () => {
                            navigate(`/details/${item?.id}`, {
                              state: {
                                ...item,
                                isMegaphone: true,
                                date: item?.start_date,
                              },
                            });
                          },
                          date: formatDate(item?.start_date),
                        }}
                      />
                    ))}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isEvent={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
