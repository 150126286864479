import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import Author from "./Author";
import HeaderOne from "../Partials/Headers/HeaderOne";

function AuthorSection() {
  const navigationData = navigations;
  useEffect(() => {
    document.body.classList.add("gray-bg");
  });
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <div className="binduz-er-breadcrumb-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-breadcrumb-box">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Pages</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Author
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="binduz-er-author-user-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-author-box">
                <div className="binduz-er-thumb">
                  <img
                    src={require(`../../assets/images/author-user.jpg`).default}
                    alt=""
                  />
                  <span>30 Post</span>
                </div>
                <div className="binduz-er-content">
                  <p>
                    On your list of places where people might access your web
                    app, Teams is probably number “not-on-the-list”. But it
                    turns out that making your app accessible where your users
                    are already working has some profound for benefits. In this
                    article, we’ll look athow Teams makes web apps for every
                    company
                  </p>
                  <div className="binduz-er-meta-author">
                    <span>
                      Rosalina D. William <span>- Founder</span>
                    </span>
                  </div>
                </div>
                <div className="binduz-er-author-contact d-flex align-content-center">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="binduz-er-contact-link">
                    <a href="#">Contact With Me</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Author />
      <Footer />
    </>
  );
}

export default AuthorSection;
