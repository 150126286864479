/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { toast } from "react-toastify";
import {
  getClassifieldCatData,
  getClassifieldSubCatData,
  getPremiumAmount,
} from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { addClassifield, getAccountData } from "../../services/AuthServices";
import {
  dateSendToBackend,
  formatDateToMMDDYYYY,
  formateMobileNumber,
  minimizeName,
  validateNum,
} from "../../lib/constant";
import PremiumModal from "../../containers/PremiumModal";
import ImagePopUp from "./ImagePopUp";

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [subCatList, setsubCatList] = useState([]);
  const [data, setdata] = useState({});
  const [isTrue, setisTrue] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFirstTime, setisFirstTime] = useState(true);
  const [isDisable, setisDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setselectedImage] = useState(null);
  const fileInputRef = useRef(null);

  // const [show, setValue] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCategory();
    getData();
    getAmount();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  const getData = async () => {
    await getAccountData()
      .then((response) => {
        setdata(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        const event = response?.data?.data?.find(
          (a) => a?.type === "Classifieds"
        );
        if (event) {
          setpremiumAmount(Number(event?.amount));
        }
      })
      .catch((error) => {});
  };

  const getCategory = async () => {
    await getClassifieldCatData("")
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSubCategory = async (id) => {
    await getClassifieldSubCatData(id)
      .then((response) => {
        setsubCatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),

    phone: string()
      .required("Please enter Mobile number.")
      .trim("Please enter Mobile number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),

    mobile: string().test(
      "mobile",
      "Alternative number must be exactly 10 digits",
      (value) => {
        if (value?.length > 0) {
          return validateNum(value);
        } else {
          return true;
        }
      }
    ),

    name: string().required("Please enter name.").trim("Please enter name."),

    contactDetail: string()
      .required("Please enter Contact Detail.")
      .trim("Please enter Contact Detail."),

    // landmark: string()
    //   .required("Please enter landmark.")
    //   .trim("Please enter landmark."),

    classiFieldDetails: string()
      .required("Please enter Classified Detail.")
      .trim("Please enter Classified Detail."),

    startDate: string()
      .required("Please enter Start Date.")
      .trim("Please enter Start Date."),

    categoryId: string()
      .required("Please enter Category.")
      .trim("Please enter Category."),

    // subCatId: string()
    //   .required("Please enter Sub Category.")
    //   .trim("Please enter Sub Category."),
  });

  const submitClicked = async (data) => {
    if (!values?.isFree || !isFirstTime) {
      setisDisable(true);
      await addClassifield(data)
        .then((response) => {
          setisDisable(false);

          if (values?.isFree || premiumAmount === 0) {
            toast("Classified added Successfully", { type: "success" });
            navigate("/classifieds");
          } else {
            response?.data?.data?.url
              ? window.location.replace(response?.data?.data?.url)
              : navigate("/classifieds");
          }
        })
        .catch((error) => {
          setisDisable(false);

          if (error?.response?.status == 422)
            toast(error?.response?.data?.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else
            toast(error?.response?.data?.message || "Something went wrong", {
              type: "error",
            });
        });
    } else {
      setModalIsOpen(true);
      setisFirstTime(false);
    }
  };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    validateForm,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      email: "",
      name: "",
      phone: "",
      contactDetail: "",
      landmark: "",
      contact_person_name: "",
      mobile: "",
      categoryId: "",
      image: null,
      subCatId: "",
      classiFieldDetails: "",
      startDate: "",
      showMyContact: false,
      isFree: true,
      amount: 0,
      premiumImage: [],
    },
    onSubmit: async () => {
      let formdata = new FormData();
      values?.premiumImage.forEach((item, index) => {
        formdata.append(`premiumImage[${index}][image]`, item);
      });

      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("cate_id", values.categoryId);
      formdata.append("contact_person_name", values.contact_person_name);
      formdata.append("landmark", values.landmark);
      formdata.append("sub_Cate_id", values.subCatId);
      formdata.append("email", values.email);
      formdata.append("address", values.address);
      formdata.append("postal", values.zip);
      formdata.append("description", values.classiFieldDetails);
      formdata.append("type", values.isFree ? "Standard" : "Premium");
      formdata.append("date", dateSendToBackend(values.startDate));
      formdata.append("conatctdetail", values.contactDetail);
      formdata.append("mobile", values.mobile);
      formdata.append("amount", values.isFree ? 0 : premiumAmount);
      // formdata.append("image", values.image);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const handleImageChange = (event, name) => {
    if (!_.isEmpty(selectedImage)) {
      setFieldValue("premiumImage", [
        ...values?.premiumImage,
        ...event?.target?.files,
      ]);
      // setFieldValue(name, file);

      let array = [];
      for (let a of [...values?.premiumImage, ...event?.target?.files]) {
        array?.push(URL.createObjectURL(a));
      }
      setselectedImage(array);
    } else {
      setFieldValue("premiumImage", [...event?.target?.files]);
      // setFieldValue(name, file);

      let array = [];
      for (let a of [...event?.target?.files]) {
        array?.push(URL.createObjectURL(a));
      }
      setselectedImage(array);
    }
  };

  const updateMyDetailsClicked = (value) => {
    if (value) {
      setFieldValue("email", data?.email);
      setFieldValue("name", data?.full_name);
      setFieldValue("phone", data?.phone_number);
      setFieldValue("contactDetail", data?.address);
      setFieldValue("landmark", data?.landmark);
      setisTrue(true);
       setTimeout(() => {
         validateForm().then((errors) => console.log("Validation Errors:"));
       }, 100);
    } else {
      setFieldValue("email", "");
      setFieldValue("name", "");
      setFieldValue("phone", "");
      setFieldValue("contactDetail", "");
      setFieldValue("landmark", data?.landmark);
      setisTrue(false);
    }
  };

  const removeImage = (name) => {
    setFieldValue("premiumImage", null);
    // setFieldValue(name, null);
    setselectedImage(null);
    fileInputRef.current.value = "";
  };

  const selectedImageDeleteClicked = (index) => {
    let arr = [];
    let imgValueArr = [];
    if (selectedImage?.length === 1) {
      setIsOpen(false);
      removeImage();
    } else {
      for (let [i, a] of selectedImage?.entries()) {
        if (i !== index) {
          arr?.push(a);
        }
      }
      for (let [i, a] of values?.premiumImage?.entries()) {
        if (i !== index) {
          imgValueArr?.push(a);
        }
      }
      setFieldValue("premiumImage", imgValueArr);

      setselectedImage(arr);
      setIsOpen(false);
    }
  };
  const selectedImageAddClicked = () => {
    fileInputRef?.current?.click();
  };
  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <h6 className="mt-1">
            Post Your Classifieds for Free! Upgrade to Premium for Enhanced
            Benefits.{" "}
            <Link
              style={{ textDecoration: "underline", color: "red" }}
              onClick={() => setModalIsOpen(true)}
            >
              Explore Premium!
            </Link>{" "}
          </h6>
          <span style={{ marginBottom: "10px" }}>
            You can only post 4 classified post per category in a month.
          </span>
          <div>
            <h6 style={{ fontWeight: "700px" }}> Classified Type: </h6>
            <input
              className="m-2"
              type="radio"
              id="standard"
              onChange={(e) => setFieldValue("isFree", true)}
              checked={values?.isFree}
            />
            <span>Standard (Free for 15 days) </span>
            <input
              className="m-2"
              type="radio"
              id="premium"
              checked={!values?.isFree}
              onChange={(e) => setFieldValue("isFree", false)}
            />
            <span>Premium (${premiumAmount} for 30 days) </span>
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="categoryId"
                id="categoryId"
                onBlur={handleBlur}
                value={values?.categoryId}
                onChange={(e) => {
                  setFieldValue("categoryId", e?.target?.value);
                  getSubCategory(e?.target?.value);
                }}
                style={{
                  borderColor:
                    touched?.categoryId && errors?.categoryId && "red",
                }}
              >
                <option value="">Select Category</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>

              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.categoryId && errors?.categoryId && (
              <span className="text-danger">{errors.categoryId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                name="subCatId"
                onBlur={handleBlur}
                value={values?.subCatId}
                style={{
                  borderColor: touched?.subCatId && errors?.subCatId && "red",
                }}
                onChange={(e) => {
                  setFieldValue("subCatId", e?.target?.value);
                }}
              >
                <option value="">Select Sub Category</option>
                {subCatList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
                {_.isEmpty(subCatList) && (
                  <option value={""}> No Data Found</option>
                )}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.subCatId && errors?.subCatId && (
              <span className="text-danger">{errors.subCatId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <ReactDatePicker
                autoComplete="off"
                placeholderText="Select Start Date*"
                value={values?.startDate}
                minDate={new Date()}
                onChange={(e) => {
                  setFieldValue("startDate", formatDateToMMDDYYYY(new Date(e)));
                }}
                onBlur={handleBlur}
                name="date"
              />
            </div>
            {touched?.startDate && errors?.startDate && (
              <span className="text-danger">{errors.startDate}</span>
            )}
          </div>

          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              //  paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "180px",
                  resize: "auto",
                  borderColor:
                    touched?.classiFieldDetails &&
                    errors?.classiFieldDetails &&
                    "red",
                }}
                maxLength={2000}
                name="classiFieldDetails"
                id="classiFieldDetails"
                onChange={handleChange}
                value={values?.classiFieldDetails}
                onBlur={handleBlur}
                cols="3"
                rows="1"
                placeholder="Your Classified Details *"
              ></textarea>
              {touched?.classiFieldDetails && errors?.classiFieldDetails && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.classiFieldDetails}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {2000 - values?.classiFieldDetails?.length}
              </div>
            </div>
          </div>
          <div>
            <input
              className="mt-4"
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="billingAddressCheckbox"
              // checked={isTrue}

              onChange={(e) => updateMyDetailsClicked(e?.target?.checked)}
            />
            <span>Update with my Contact details</span>
          </div>
          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Address *"
                name="contactDetail"
                id="contactDetail"
                value={values?.contactDetail}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.contactDetail && errors?.contactDetail && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.contactDetail && errors?.contactDetail && (
              <span className="text-danger">{errors.contactDetail}</span>
            )}
          </div>
          {/* <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Landmark"
                name="landmark"
                id="landmark"
                value={values?.landmark}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
              />
             
            </div>
            {touched?.landmark && errors?.landmark && (
              <span className="text-danger">{errors.landmark}</span>
            )}
          </div> */}
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Email *"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.email && errors?.email && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.email && errors?.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number *"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Alternative Mobile Number "
                name="mobile"
                id="mobile"
                value={values.mobile}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue(
                    "mobile",
                    formateMobileNumber(e?.target?.value)
                  );
                }}
                style={{
                  borderColor: touched?.mobile && errors?.mobile && "red",
                }}
                // onChange={handleChange}
              />
            </div>
            {touched?.mobile && errors?.mobile && (
              <span className="text-danger">{errors.mobile}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Contact Person Name"
                name="contact_person_name"
                id="contact_person_name"
                value={values.contact_person_name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
          {!values?.isFree && (
            <div className="col-lg-6 ">
              <div className="binduz-er-input-box">
                <label htmlFor="image" className="custom-file-label">
                  {minimizeName(values?.premiumImage?.[0]?.name, 25) ||
                    "Choose Image"}
                </label>
                <input
                  type="file"
                  style={{ paddingLeft: "17px" }}
                  placeholder="Logo *"
                  accept="image/*"
                  name="image"
                  id="image"
                  ref={fileInputRef}
                  multiple
                  // value={imagePreview}
                  onChange={(e) => {
                    handleImageChange(e, "image");
                  }}
                  onBlur={handleBlur}
                  className="form-control custom-file-input"
                />
              </div>
              {selectedImage && (
                <>
                  <a
                    // href={selectedImage}
                    onClick={() => setIsOpen(true)}
                    // target="_blank"
                    className="m-1"
                  >
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a className="m-1" onClick={() => removeImage("image")}>
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 500px )</span>
            </div>
          )}
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable
            ? "Loading..."
            : values?.isFree
            ? "Submit"
            : "Proceed to payment"}
        </button>
      </form>
      <ImagePopUp
        images={selectedImage}
        fileInputRef={fileInputRef}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteClicked={selectedImageDeleteClicked}
        addClicked={selectedImageAddClicked}
      />
      <PremiumModal
        yesClicked={() => {
          setFieldValue("isFree", false);
          setModalIsOpen(false);
        }}
        noClicked={() => {
          setFieldValue("isFree", true);
          setModalIsOpen(false);
        }}
        title={"CLASSIFIED"}
        amount={premiumAmount}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        topLinesArray={[
          "You can post your classifieds for free on our platform, and they will be valid for 15 days.Enjoy the convenience and reach of our service without any cost!",
          `For just $${premiumAmount}, you can upgrade your classified and unlock these exclusive benefits:`,
        ]}
        descArray={[
          " Your classified will be active for 30 days, giving you double the exposure.",
          " Your classified will be moved to a spotlight position, ensuring better visibility and traction.",
          " Upload up to 4 images, making your classified more attractive and informative.",
          " Your classified will appear at the top of search results, catching the eye of potential customers first.",
        ]}
        titleArray={[
          "Extended Visibility:-",
          "⁠Spotlight Position:-",
          "Enhanced Media Uploads:-",
          "Priority Listing:-",
        ]}
        lastLine={`Upgrading to Premium ensures your classified stands out, attracts more attention, and reaches the right audience effectively. Post for free and upgrade to Premium to maximize your results!`}
      />
    </div>
  );
};

export default ClassiFieldForm;
