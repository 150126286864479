import React, { useEffect, useState } from "react";
import CardRowModule from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getJobsData, getJobsSubCatData } from "../../services/HomeServices";
import {
  formatDate,
  formatDateToMMYYYY,
  formatDateToMMDDYYYY,
  plainString,
} from "../../lib/constant";
import azilogo from "../../assets/images/azlogomessage.jpeg";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../containers/Loader";
import { Link, useNavigate } from "react-router-dom";

import LatestClassifiedModule from "../../containers/NewDesignModules/LatestClassifiedModule";
import EventDesign from "../NewDesign/EventDesign";

const MainSectionNew = () => {
  const [data, setdata] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [selectedCat, setselectedCat] = useState();
  const [links, setlinks] = useState([]);
  const [catList, setcatList] = useState([]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    getData();
    setpage(1);
  }, [search, selectedMonth, selectedCat]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getCategory();
  }, []);

  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 700;

  const getData = async () => {
    setisLoading(true);
    await getJobsData(
      search,
      formatDateToMMYYYY(selectedMonth),
      page,
      selectedCat
    )
      .then((response) => {
        setdata(response?.data?.data?.data);
        setisLoading(false);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getCategory = async () => {
    await getJobsSubCatData()
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      <div className="container-fluid bg_drackgray">
        <div className="container">
          <div className="row prime_listings">
            <div className="col-6">
              <h2>Jobs Board</h2>
            </div>
            <div className="col-6">
              {" "}
              <Link to="/jobs-post" className="btn_post">
                Post a Job <i className="fas fa-plus" />
              </Link>{" "}
            </div>
          </div>
          {/*<!-- search --> */}
          <div className="row classified_sc pt-3">
            <div className="col-sm-2">
              <label>Search Jobs</label>
            </div>
            <div className="col-lg-3">
              <div className="date-picker">
                <ReactDatePicker
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  autoComplete="off"
                  placeholderText="Select Month & Year"
                  selected={selectedMonth}
                  onChange={(e) => {
                    setselectedMonth(e);
                  }}
                  name="date"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="binduz-er-select ">
                <select
                  className="category"
                  placeholder="Data"
                  value={selectedCat}
                  onChange={(e) => setselectedCat(e?.target?.value)}
                >
                  <option value={""}>Select Category</option>
                  {catList?.map((x, i) => (
                    <option key={i} value={x?.id}>
                      {x?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    getData();
                  }}
                >
                  <div
                    className="binduz-er-input-box"
                    style={{ width: "270px" }}
                  >
                    <input
                      type="text"
                      onChange={(e) => setsearch(e?.target?.value)}
                      value={search}
                      placeholder="Search from here..."
                      style={{ lineHeight: "38px" }}
                    />
                    <button type="submit" style={{ height: "38px" }}>
                      <i className="fal fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*<!-- search ends --> */}
          {/*<!-- listings --> */}
          <div className="row pt-4">
            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((x, index) => (
                      <div
                        key={index}
                        className="col-sm-3 p-1"
                        onClick={() => {
                          navigate(`/jobs-detail/${x?.id}`);
                        }}
                      >
                        <div className="job_card">
                          <img
                            className="card-img-top"
                            e
                            src={x?.image || azilogo}
                            alt="Company Logo"
                          />
                          <label>
                            <i className="far fa-calendar" />{" "}
                            {formatDateToMMDDYYYY(x?.date)}
                          </label>
                          <div
                            style={{ minHeight: "80px" }}
                            className="line-clump-2"
                            dangerouslySetInnerHTML={{
                              __html: x?.description,
                            }}
                          />{" "}
                          <Link to={`/jobs-detail/${x?.id}`} state={x}>
                            More
                          </Link>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <h5 style={{ textAlign: "center", marginTop: "70px" }}>
                    No Data found
                  </h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>

          <Pagination links={links} setpage={setpage} />
          {/* listings ends */}
        </div>
      </div>
      <LatestClassifiedModule />
      <EventDesign />
    </>
  );
};

export default MainSectionNew;
