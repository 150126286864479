import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Loader from './Loader';
import _ from 'lodash';
import { getPremiumDoctorData } from '../services/HomeServices';
import { toast } from 'react-toastify';

const DoctorModule = () => {
    const [isLoading, setisLoading] = useState(false)
    const [data, setdata] = useState([])

useEffect(() => {
  getData();
}, []);
  const getData = async (id) => {
    setisLoading(true);
    await getPremiumDoctorData()
      .then((response) => {
        let resData = response?.data?.data?.data;
        setdata(resData);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };


  return (
    <div className='mt-5 mb-5'>
      <div className="binduz-er-top-news-title">
        <h3 className="binduz-er-title">Doctors</h3>
      </div>
      <div
        className="binduz-er-social-list"
        style={{ height: "450px", overflow: "scroll" }}
      >
        <div className="binduz-er-list">
          {!isLoading ? (
            <>
              {!_.isEmpty(data) ? (
                <>
                  {data?.map((x) => (
                    <Link
                      className="category-search"
                      to={{ pathname: `/doctor/${x?.id}` }}
                      state={x}
                    >
                      <span>
                        <h6
                          className="binduz-er-title top-stories-list-line"
                          style={{ cursor: "pointer", marginBottom: "2px" }}
                        >
                          <a>{x?.name}</a>
                        </h6>
                        <span
                          style={{
                            cursor: "pointer",
                            marginBottom: "0px",
                          }}
                          className="line-clump-2"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.comment,
                            }}
                          />
                          {/* {x?.description} */}
                        </span>
                      </span>
                    </Link>
                  ))}
                </>
              ) : (
                <Link
                  className="category-search"
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                    </span>
                  </span>
                </Link>
                // <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
}

export default DoctorModule