import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getEventDetail } from "../../services/HomeServices";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../containers/Loader";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import Hero from "../Partials/Headers/HeaderOne/Hero";
import { motion } from "framer-motion";
import BannerImage from "../Partials/Headers/HeaderOne/BannerImage";
import ReactPlayer from "react-player";
import _, { isEmpty } from "lodash";
import EventDetailPage from "./EventDetailPage";
import SideADModule from "../../containers/PostCards/SideADModule";
import NewEventDetailPage from "./NewEventDetailPage";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (params?.location_id) {
      if (typeof Number(params?.location_id) === "number") {
        getData();
      } else {
        navigate("calender");
      }
    } else {
      if (_.isEmpty(location?.state)) {
        navigate("/", { replace: true });
      } else {
        setdata(location?.state);
      }
    }
  }, [params, location]);

  const formatDateForEvent = (date) => {
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedTime = date?.toLocaleTimeString("en-US", optionsTime);

    return `${formatDateToMMDDYYYY(date)} ${formattedTime}`;
  };

  const getData = async () => {
    setisLoading(true);
    try {
      const response = await getEventDetail(params?.location_id, params?.id);
      setdata(response?.data?.data);
      setisLoading(false);
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    } finally {
      // setisLoading(false);
    }
  };
let imageForNotParamsId =
  data?.event?.image || data?.image || data?.user?.profile_image;
  return (
    <>
      {/* {data?.event?.flyer && (
        <BannerImage img={data?.event?.flyer} isFullScreen={true} />
      )} */}

      {!isLoading ? (
        !params?.location_id ? (
          <div
            className="binduz-er-author-user-area"
            style={{ paddingTop: "30px" }}
          >
            <div className="container">
              <div className="row pb-5">
                <div className="col-lg-9">
                  {/* <motion.div
                  className="binduz-er-meta-author"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  transition={{ duration: 4 }}
                >
                  <div className="binduz-er-top-news-title">
                    <h3 className="binduz-er-title">{data?.event?.name}</h3>
                  </div>
                </motion.div> */}
                  {imageForNotParamsId && (
                    <div
                      className="binduz-er-thumb"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={imageForNotParamsId}
                        style={{
                          objectFit: "fill", // Ensure the image stretches to fit both dimensions without cropping
                        }}
                        alt="Event"
                        className="event-image image-shadow"
                      />
                    </div>
                  )}
                  <div className="row justify-content-center mt-4">
                    <div className="col-lg-11">
                      <div
                        className="binduz-er-author-item mb-4"
                        style={{
                          borderRadius: "15px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow
                        }}
                      >
                        <div className="binduz-er-content">
                          {/* <div className="binduz-er-meta-item">
                            <div className="binduz-er-meta-date mb-3">
                              <span>
                                <i className="fal fa-calendar-alt"></i>{" "}
                                {params?.location_id
                                  ? formatDateForEvent(
                                      new Date(data?.event?.created_at)
                                    )
                                  : formatDateToMMDDYYYY(
                                      new Date(data?.created_at)
                                    )}
                              </span>
                            </div>
                          </div> */}

                          {data?.location?.name && (
                            <div className="col-md-4">
                              <p className="card-text text-muted">
                                <strong>Organized By :</strong>{" "}
                                <span style={{ color: "#FF6821" }}>
                                  {data?.location?.name}{" "}
                                </span>
                              </p>
                            </div>
                          )}
                          <div className="col-md-4" style={{padding:'0px'}}>
                            <p className="card-text text-muted">
                              <strong>Date & Time :</strong>{" "}
                              <span style={{ color: "#FF6821" }}>
                                {params?.location_id
                                  ? formatDateForEvent(
                                      new Date(data?.event?.created_at)
                                    )
                                  : formatDateToMMDDYYYY(
                                      new Date(data?.created_at)
                                    )}
                              </span>
                            </p>
                          </div>
                          {data?.location?.address && (
                            <div className="col-md-4">
                              <p className="card-text text-muted">
                                <strong>Venue :</strong>{" "}
                                <span style={{ color: "#FF6821" }}>
                                  {data?.location?.address}
                                </span>
                              </p>
                            </div>
                          )}

                          <div className="binduz-er-text mt-3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: params?.location_id
                                  ? data?.event?.description
                                  : data?.message,
                              }}
                            />
                          </div>
                        </div>
                        {!_.isEmpty(data?.event?.video) && (
                          <div
                            className="row"
                            style={{
                              paddingLeft: "60px",
                              paddingRight: "60px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {data?.event?.video?.map((x, i) => {
                              return (
                                <div className="col-lg-6">
                                  <div className="binduz-er-main-posts-item">
                                    <ReactPlayer
                                      url={x?.link}
                                      controls={true} // Show video controls like play, pause, etc.
                                      width="100%" // Adjust the width as per your need
                                      height="311px" // Adjust the height as per your need
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-3">
                  <SideADModule isEvent={true} isClassifield={true} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <EventDetailPage data={data} />
          <NewEventDetailPage data={data} />
        )
      ) : (
        <div style={{ height: "500px", display: "flex", alignItems: "center" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default MainSection;
