import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import az_new_paper from "../../assets/images/az_new_paper.png";
import classified_icon_01 from "../../assets/images/classified_icon_01.png"; //art and dance
import classified_icon_02 from "../../assets/images/classified_icon_02.png"; // automobiles
import classified_icon_03 from "../../assets/images/classified_icon_03.png"; //beuty services
import classified_icon_04 from "../../assets/images/classified_icon_04.png"; // car pool
import classified_icon_05 from "../../assets/images/classified_icon_05.png"; // care takers
import classified_icon_06 from "../../assets/images/classified_icon_06.png"; // food and catering
import classified_icon_07 from "../../assets/images/classified_icon_07.png"; // for sale
import classified_icon_08 from "../../assets/images/classified_icon_08.png"; // real estate
import classified_icon_09 from "../../assets/images/classified_icon_09.png"; // rental
import classified_icon_10 from "../../assets/images/classified_icon_10.png"; // training classes
import classified_icon_11 from "../../assets/images/classified_icon_11.png"; // travel
import classified_icon_12 from "../../assets/images/classified_icon_12.png"; // others
import iconStar from "../../assets/images/prime_star.png";

import {
  getClassifieldCatData,
  getClassifieldPremiumData,
} from "../../services/HomeServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { formatDate, minimizeName, plainString } from "../../lib/constant";

const iconArray = [
  classified_icon_01,
  classified_icon_02,
  classified_icon_03,
  classified_icon_04,
  classified_icon_05,
  classified_icon_06,
  classified_icon_07,
  classified_icon_08,
  classified_icon_09,
  classified_icon_10,
  classified_icon_11,
  classified_icon_12,
];

const ClassifiedDesign = ({ onlyClassified }) => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [categories, setcategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      getData();
      getCategories();
    }, 700);
  }, []);

  const getData = async (pageCount) => {
    setisLoading(true);
    await getClassifieldPremiumData(pageCount, 12)
      .then((response) => {
        setdata(response?.data?.data?.data);

        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);

        toast("Something went wrong", { type: "error" });
      });
  };

  const getCategories = async () => {
    await getClassifieldCatData()
      .then((response) => {
        const array = response?.data?.data?.filter(
          (a) => a?.name !== "Pin Hopes"
        );
        setcategories(array);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div>
      <div className="container-fluid bg_drackgray">
        <div className="container">
          <div className="row prime_listings">
            <div className="col-6">
              <h2>Prime Listings</h2>
            </div>
            <div className="col-6">
              {" "}
              <Link to="'/classifield-post" className="btn_post">
                Post <i className="fas fa-plus" />
              </Link>{" "}
            </div>
          </div>
          <div className="row pt-4">
            {data?.slice(0, 8)?.map((x) => (
              <div
                className="col-sm-3 p-2"
                onClick={() => {
                  navigate(`/classifield-detail/${x?.id}`);
                }}
              >
                <div className="prime_card">
                  {" "}
                  <img
                    src={iconStar}
                    alt="star"
                    className="prime_star"
                    style={{
                      marginTop: "8px",
                    }}
                  />{" "}
                  <img
                    className="card-img-top"
                    style={{ height: "174px", width: "274px" }}
                    src={x?.image || x?.images[0]?.image || img}
                    alt="Card image cap"
                  />
                  <p className="card-text line-clump-2">
                    {minimizeName(plainString(x?.description), 90)}
                    
                    {/* <Link to={`/classifield-detail/${x?.id}`}>More</Link> */}
                  </p>
                  <div className="row">
                    <div className="col-6">
                      <label>
                        <i className="far fa-calendar" />{" "}
                        {formatDate(x?.start_date)}
                      </label>
                    </div>
                    <div className="col-6">
                      <Link to={`/classifield-detail/${x?.id}`}>More</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!onlyClassified && (
        <div className="container-fluid classifieds_main bg_gray">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <div className="col-6">
                  <h2>Classifieds</h2>
                </div>
                <div className="row pt-4">
                  {categories?.map((x, i) => {
                    return (
                      <div className="col-sm-3 col-6">
                        <Link
                          to={`/classifield/${x?.id}`}
                          className={`classfi_icon cf_icon${i + 1}`}
                        >
                          <img
                            src={x?.image || classified_icon_01}
                            alt={x?.name}
                          />{" "}
                          <span>{minimizeName(x?.name)}</span>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="col-6">
                  <h2>News</h2>
                </div>
                <div className="row pt-4">
                  {" "}
                  <a href="http://www.azindiatimes.com/" target="_blank">
                    <img src={az_new_paper} alt="azindia new paper" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassifiedDesign;
