import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import SideADModule from "../../containers/PostCards/SideADModule";
import _ from "lodash";
import { formatDate, formatDateToMMDDYYYY } from "../../lib/constant";

const MainSection = () => {
  const location = useLocation();
  const [data, setdata] = useState(location?.state);

  let pages;
  let to;
  if (data?.isAzTimes) {
    pages = "AZ Times";
    to = "/articles";
  } else if (data?.isMegaphone) {
    pages = "Megaphone";
    to = "/megaphone";
  } else {
    pages = "Top Stories";
    to = "/top-stories";
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  // const formatDate = (date) => {
  //   const optionsDate = {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };

  //   const formattedDate = date?.toLocaleDateString("en-US", optionsDate);

  //   return `${formattedDate}`;
  // };

  return (
    <>
      {/* <Breadcrumb home="Home" pages={pages} to={to} title={data?.title} /> */}
      <div
        className="binduz-er-author-user-area"
        style={{ paddingTop: "30px" }}
      >
        <div className=" container">
          <div className="row">
            <motion.div
              className=" col-lg-9"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              transition={{ duration: 4 }}
            >
              {/* <div
                className="binduz-er-author-box"
                style={{
                  background: "none",
                  display:'flex',
                  justifyContent:'center',
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              > */}

              <div
                className=" col-lg-12 d-flex justify-content-center align-items-center"
                style={{
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  display: "flex",
                }}
              >
                <div>
                  {!_.isEmpty(data?.image) && (
                    <img
                      src={data?.image}
                      alt="BannerImage"
                      className="img-fluid  shadow"
                      style={{
                        borderRadius: "20px",
                        width: "100%",
                        height: "45vh",
                        // objectFit:'contain',
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    />
                  )}
                </div>
              </div>
              {/* </div> */}
              <div className="m-5 mb-5">
                <div className="binduz-er-meta-item ">
                  <div className="binduz-er-content">
                    <div className="binduz-er-meta-author">
                      <h4> {data?.title}</h4>
                    </div>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>{" "}
                      {data?.date
                        ? formatDate(data?.date)
                        : formatDateToMMDDYYYY(new Date(data?.created_at))}
                      {/* {formatDate(new Date(data?.created_at))} */}
                    </span>
                  </div>
                </div>

                <div className="binduz-er-text mt-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </div>
              </div>
            </motion.div>
            <div className=" col-lg-3">
              <SideADModule isEvent={true} isClassifield={true} zoom={11} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSection;
