import React from "react";
import AccountDetailLayout from "./AccountDetailLayout";
import MessageSidebar from "./MessageSidebar";
import SideADModule from "../../containers/PostCards/SideADModule";
import LatestClassifiedModule from "../../containers/NewDesignModules/LatestClassifiedModule";
import EventDesign from "../NewDesign/EventDesign";

const MainSection = () => (
  <section className={`binduz-er-trending-area`}>
    <div className="container pb-5">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <AccountDetailLayout />
        </div>
        {/* <div className="col-lg-3 col-md-12">
          <MessageSidebar />
          <SideADModule  isEvent={true} />
        </div> */}
      </div>
      </div>
      <LatestClassifiedModule />
      <EventDesign />
  </section>
);

export default MainSection;
