import React, { useEffect, useState } from "react";
import SideADModule from "../../containers/PostCards/SideADModule";
import {
  formatDate,
  formatDateToMMDDYYYY,
  plainString,
} from "../../lib/constant";
import ReactPlayer from "react-player";
import _ from "lodash";
import MapModule from "./MapModule";

const EventDetailPage = ({ data }) => {
  const [isEmptyVideoArray, setisEmptyVideoArray] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  useEffect(() => {
    if (data?.event?.video?.some((a) => a?.link)) {
      setisEmptyVideoArray(false);
    }
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Website",
          text: "Check out this awesome content!",
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (err) {
        console.error("Error sharing the content:", err);
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };
  const formatDateForEvent = (date) => {
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedTime = date?.toLocaleTimeString("en-US", optionsTime);

    return `${formatDateToMMDDYYYY(date)} ${formattedTime}`;
  };

  let fullAddress = `${data?.location?.name}, ${data?.location?.address}, ${data?.location?.city?.city} ,${data?.location?.state?.state} ,${data?.location?.zipcode}`;
  let organizedByName =
    data?.event?.association?.company_name || data?.event?.user?.full_name;

    
  return (
    <section
      className="binduz-er-author-item-area pb-20"
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
         
          <div
            className=" col-lg-9"
            style={{ paddingRight: "30px", paddingLeft: "30px" }}
          >
           {/* {!_.isEmpty(data?.event?.flyer)&& <div
              className=" col-lg-12 d-flex justify-content-center align-items-center"
              style={{
                paddingLeft: "25px",
                paddingRight: "25px",
                display: "flex",
              }}
            >
              <div>
               
                  <img
                    src={data?.event?.flyer}
                    alt="BannerImage"
                    className="img-fluid  shadow"
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "65vh",
                      // objectFit:'contain',
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  />
                
              </div>
            </div>} */}
            <div
              className="binduz-er-author-item mb-40 mt-4"
              style={{ borderRadius: "10px" }}
            >
              {data?.event?.flyer && (
                <div
                  className="binduz-er-thumb"
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    src={data?.event?.flyer}
                    alt="flyerImage"
                    style={{
                      height: "75vh",
                      display: "flex",
                      objectFit: "contain",
                      backgroundColor: "white",
                    }}
                  />
                </div>
              )}
              <div className="binduz-er-meta-list d-flex">
                <ul
                  className="d-flex mt-3"
                  style={{ gap: "30px", listStyleType: "none" }}
                >
                  {/* <li>
                    <i className="fal fa-eye"></i> 5k
                  </li> */}
                  <li style={{ cursor: "pointer" }} onClick={handleShare}>
                    <i className="fal fa-share"></i> Share
                  </li>
                </ul>
              </div>

              <div className="binduz-er-blog-details-box">
                <div className="">
                  <div
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="d-lg-flex mt-2 mb-2"
                  >
                    <div>
                      <h3 className="binduz-er-title">{data?.event?.name}</h3>

                      <div className="binduz-er-meta-item">
                        <div className="binduz-er-meta-date mb-3">
                          <span>
                            <i className="fal fa-calendar-alt"></i>{" "}
                            {formatDate(data?.event?.location[0]?.date)}
                          </span>
                        </div>
                      </div>
                      {organizedByName && (
                        <div className="">
                          <p className="card-text text-muted">
                            <strong>
                              <span class="icon">🏢</span>Organized By :
                            </strong>{" "}
                            <span style={{ color: "#FF6821" }}>
                              {organizedByName}{" "}
                            </span>
                          </p>
                        </div>
                      )}
                      <div className="mt-3 mb-3">
                        <p className="card-text text-muted">
                          <strong>
                            <span class="icon">📅</span>Date & Time :
                          </strong>{" "}
                          <span style={{ color: "#FF6821" }}>
                            {formatDate(data?.event?.location[0]?.date)}
                            &nbsp;&nbsp;
                            {data?.event?.location[0]?.time}
                          </span>
                        </p>
                      </div>
                      {data?.location?.address && (
                        <div className="">
                          <p className="card-text text-muted">
                            <strong>
                              <span class="icon">📍</span>Venue :
                            </strong>{" "}
                            <span style={{ color: "#FF6821" }}>
                              {/* {data?.location?.address} */}
                              {fullAddress}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div>
                      {data?.event?.image && (
                        <div className=" col-lg-12 ">
                          <div
                            className="binduz-er-blog-details-thumb mt-25"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ maxHeight: "200px", maxWidth: "200px" }}
                              className="image-shadow"
                              src={data?.event?.image}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {data?.event?.sponsor_logo && (
                    <div
                      className="binduz-er-latest-news-item mt-4 mb-4"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="binduz-er-thumb"
                        style={{ borderRadius: "5px" }}
                      >
                        <img
                          src={data?.event?.sponsor_logo} // Use your image source here
                          alt="Sponsor Logo"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px",
                            border: " 3px solid ",
                            borderImage:
                              "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet) 1",
                            boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.3)",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="binduz-er-text mt-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.event?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="binduz-er-author-item mb-40"
              style={{ borderRadius: "10px" }}
            >
              {!isEmptyVideoArray && (
                <div
                  className="row"
                  style={{
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                    alignItems: "center",
                  }}
                >
                  {data?.event?.video?.map((x, i) => {
                    return (
                      <div className="col-lg-6">
                        <div
                          className="binduz-er-main-posts-item"
                          style={{ borderRadius: "10px" }}
                        >
                          <ReactPlayer
                            style={{
                              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.4)",
                              borderRadius: "10px", // Apply border-radius to the ReactPlayer
                            }}
                            url={x?.link}
                            controls={true} // Show video controls like play, pause, etc.
                            width="100%" // Adjust the width as per your need
                            height="311px" // Adjust the height as per your need
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {!_.isEmpty(data?.location?.latitude) && (
                <MapModule
                  lat={data?.location?.latitude}
                  lng={data?.location?.longitude}
                />
              )}
            </div>
          </div>

          <div className=" col-lg-3">
            <SideADModule isEvent={true} isClassifield={true} zoom={11} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventDetailPage;
